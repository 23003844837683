import { FC, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  getFareModel,
  setFareModel,
} from 'features/fareModel/fareModelActions';
import { fareModelDistancesLoadingSelector } from 'features/loading/loadingSelectors';
import { Button, CardHeader, Icon, Layout, Loadable } from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { FareModelDistanceTabs } from 'routes/FareModels/FareModelDistances/FareModelDistanceTabs';
import {
  getFareModelCommercialDistanceStops,
  getFareModelDistanceFares,
} from 'features/fareModel/fareModelDistanceActions';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      overflowX: 'hidden',
      zIndex: 1,
      flex: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      flex: '0 !important',
      minHeight: 'unset !important',
    },
  }),
  {
    name: 'FareModelDistances',
  }
);

export const FareModelDistances: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const loading = useSelector(fareModelDistancesLoadingSelector);
  const fareModel = useSelector(fareModelSelector);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      dispatch(setFareModel());
      await dispatch(getFareModel(id));
      await dispatch(getFareModelDistanceFares());
      await dispatch(getFareModelCommercialDistanceStops(id));
    })();
  }, [dispatch, id]);

  const classes = useStyles();
  return (
    <Loadable loading={loading}>
      <div className={classes.root}>
        <Layout
          classes={{ root: classes.header }}
          header={
            <CardHeader
              title={
                <TransSubtitle
                  i18nKey="distanceFares"
                  values={{ name: fareModel?.name }}
                />
              }
            >
              <Button
                variant="text"
                startIcon={<Icon name="arrow-left-circle" />}
                onClick={() =>
                  history.push(`/fare-models/edit/${fareModel?.id}`)
                }
                textColorString="text.warning"
              >
                <TransButton i18nKey="back" />
              </Button>
            </CardHeader>
          }
        />
        <FareModelDistanceTabs />
      </div>
    </Loadable>
  );
};
