import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  PeriodField,
  SearchForm,
  useForm,
} from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { ZoneMapSearchFilter } from 'dto/zoneMap';
import {
  getZoneMapList,
  setZoneMapFilter,
} from 'features/zoneMap/zoneMapActions';
import { TransField } from 'i18n/trans/field';
import { SelectField, TextField } from '@fleet/shared/form';
import { useLocation } from 'react-router-dom';
import { SelectOwnerField } from 'components/SelectOwnerField';
import { Option } from '@fleet/shared/dto/option';
import { fetchFareModels } from 'features/fareModel/fareModelService';
import { mapFilterSelector } from 'features/zoneMap/zoneMapSelectors';

export const ZoneMapSearchForm: FC = () => {
  const [fareModelOptions, setFareModelOptions] = useState<
    Array<Option<string>>
  >([]);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(mapFilterSelector);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const fetchFareModelOptions = useCallback(async () => {
    setFareModelOptions(makeClassificationOptions(await fetchFareModels()));
  }, []);

  useEffect(() => {
    fetchFareModelOptions();
  }, [fetchFareModelOptions]);

  const onSubmit = useCallback(
    (values: ZoneMapSearchFilter) => {
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getZoneMapList(values));
      });
    },
    [dispatch]
  );

  const initialValues = useMemo(() => {
    const query = new URLSearchParams(search);

    return query.get('fareModelId')
      ? { fareModelId: query.get('fareModelId')! }
      : { ownerId: currentBusinessEntityId, ...filter };
  }, [currentBusinessEntityId, filter, search]);

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setZoneMapFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm<ZoneMapSearchFilter>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setZoneMapFilter({}));
  }, [dispatch, form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={1}>
              <TextField name="name" label={<TransField i18nKey="mapName" />} />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="fareModelId"
                options={fareModelOptions}
                label={<TransField i18nKey="fareModel" />}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectOwnerField showEmptyOption />
            </Grid>
            <PeriodField
              from={{
                name: 'validFrom',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'validTo',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
              }}
            />
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
