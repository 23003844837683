import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  CardHeader,
  decimalToPercentage,
  FormControl,
  FormProvider,
  Layout,
  Loadable,
  percentageToDecimal,
  useForm,
  useLoaded,
} from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { CardContent, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscountDetailsForm } from 'routes/PromotionalDiscounts/PromotionalDiscountDetails/Form';
import { PromotionalDiscountDetailsFormAdvanced } from 'routes/PromotionalDiscounts/PromotionalDiscountDetails/FormAdvanced';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { PromotionalDiscountValues } from 'dto/promotionalDiscount';
import {
  createPromotionalDiscount,
  getPromotionalDiscount,
  getPromotionalDiscounts,
  updatePromotionalDiscount,
} from 'features/promotionalDiscount/promotionalDiscountActions';
import { Validity } from 'dto/common';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { promotionalDiscountLoadingSelector } from 'features/loading/loadingSelectors';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { PromotionalDiscountTabs } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    details: {
      flex: 1,
    },
    tabs: {},
  }),
  {
    name: 'PromotionalDiscountDetails',
  }
);

interface PromotionalDiscountDetailsProps {}

export const PromotionalDiscountDetails: FC<PromotionalDiscountDetailsProps> =
  () => {
    const dispatch = useDispatch();
    const currentPromotionalDiscount = useSelector(promotionalDiscountSelector);
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );
    const { action, id } = useParams<{ action: string; id?: string }>();

    useEffect(() => {
      if (id) {
        dispatch(getPromotionalDiscount(id));
      }
    }, [dispatch, id]);

    const isEditing = useMemo(
      () => action === 'edit' && Boolean(id),
      [action, id]
    );
    const loading = useSelector(promotionalDiscountLoadingSelector);
    const loaded = useLoaded(loading);

    const promotionalDiscount = useSelector(promotionalDiscountSelector);
    const [dateFormat] = isoDateTimeFormat.split("'T'");
    const startDateFormat = `${dateFormat}'T'00:00:00`;

    const initialValues = useMemo<Partial<PromotionalDiscountValues>>(
      () => ({
        ownerId: currentBusinessEntityId,
        isPublic: false,
        discountTypeId: 'BONUS_SCHEME_DISCOUNT_TYPE.PERCENTAGE',
        calculationBasisId: 'DISCOUNT_CALCULATION_BASIS.PASSENGER_TYPE_PRICE',
        validity: {
          from: formatDate(new Date(), startDateFormat),
        } as Validity,
        tripDateValidity: {
          from: formatDate(new Date(), startDateFormat),
        } as Validity,
        ...(promotionalDiscount && {
          ...promotionalDiscount,
          discountPercentageAmount: promotionalDiscount.discountPercentageAmount
            ? decimalToPercentage(promotionalDiscount.discountPercentageAmount)
            : null,
          discountTypeId: promotionalDiscount.discountType.id,
          calculationBasisId: promotionalDiscount.calculationBasis.id,
          salesLimitTypeId: promotionalDiscount.salesLimitType?.id,
          currencyId: promotionalDiscount.currency.id,
          inventoryClasses: promotionalDiscount.inventoryClasses.map(
            ({ id }) => id
          ),
          ticketMinimalCost: promotionalDiscount.ticketMinimalCost.map(
            ({ amount, currency: { id: currencyId } }) => ({
              amount,
              currencyId,
            })
          ),
          journeyWayId: promotionalDiscount.journeyWay?.id,
          journeyDirectionId: promotionalDiscount.journeyDirection?.id,
          journeyWayTypeId: promotionalDiscount.journeyWayType?.id,
          journeyTypeId: promotionalDiscount.journeyType?.id,
        }),
      }),
      [currentBusinessEntityId, promotionalDiscount, startDateFormat]
    );

    const alert = useAlert();
    const history = useHistory();
    const onSubmit = useCallback(
      async ({
        excludedTravelDays,
        excludedPurchaseDays,
        discountPercentageAmount,
        ...rest
      }: PromotionalDiscountValues) => {
        const payload = {
          ...rest,
          discountPercentageAmount: discountPercentageAmount
            ? percentageToDecimal(discountPercentageAmount)
            : null,
          excludedTravelDays: excludedTravelDays?.map((day) =>
            formatDate(day, dateFormat)
          ),
          excludedPurchaseDays: excludedPurchaseDays?.map((day) =>
            formatDate(day, dateFormat)
          ),
        };
        const data = await dispatch(
          (!payload.id ? createPromotionalDiscount : updatePromotionalDiscount)(
            payload
          )
        ).unwrap();

        if (!payload.id) {
          alert.success(
            <TransAlert i18nKey="promotionalDiscountCreated" values={data} />
          );
          history.replace(`/promotional-discounts/edit/${data.id}`);
        } else {
          alert.success(<TransAlert i18nKey="promotionalDiscountUpdated" />);
          dispatch(getPromotionalDiscount(data.id));
        }

        dispatch(getPromotionalDiscounts());
      },
      [alert, dateFormat, dispatch, history]
    );

    const { form, handleSubmit, dirty, submitting } =
      useForm<PromotionalDiscountValues>({
        subscription: { dirty: true, submitting: true, values: true },
        initialValues,
        onSubmit,
      });
    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    const classes = useStyles();
    return (
      <Loadable loading={loading} portal>
        <div className={classes.root}>
          <Layout
            header={
              <CardHeader
                title={
                  <TransTitle
                    i18nKey={
                      promotionalDiscount
                        ? 'promotionalDiscount'
                        : 'newPromotionalDiscount'
                    }
                    values={{ name: promotionalDiscount?.name }}
                  />
                }
              />
            }
          >
            <CardContent sx={{ p: 2, pt: 1.5 }}>
              <FormProvider {...form}>
                <Grid
                  component="form"
                  onSubmit={handleSubmit}
                  container
                  columns={5}
                  spacing={2}
                >
                  <PromotionalDiscountDetailsForm />
                  {isEditing && loaded && (
                    <PromotionalDiscountDetailsFormAdvanced />
                  )}
                  <Grid item xs={12} sx={{ p: '0 !important' }} />
                  <Grid item xs="auto" sx={{ ml: 'auto' }}>
                    <Stack direction="row" flexWrap="nowrap">
                      <FormControl label="&nbsp;">
                        <Button
                          variant="text"
                          sx={{ whiteSpace: 'nowrap' }}
                          {...(!isEditing && {
                            component: Link,
                            to: '/promotional-discounts',
                          })}
                          {...(isEditing && {
                            onClick: handleReset,
                            disabled: !dirty,
                          })}
                        >
                          <TransButton
                            i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                          />
                        </Button>
                      </FormControl>
                      <FormControl label="&nbsp;">
                        <Button
                          variant="contained"
                          icon={isEditing ? 'check' : 'plus'}
                          type="submit"
                          disabled={submitting}
                        >
                          <TransButton
                            i18nKey={isEditing ? 'save' : 'create'}
                          />
                        </Button>
                      </FormControl>
                    </Stack>
                  </Grid>
                </Grid>
              </FormProvider>
            </CardContent>
          </Layout>
          {currentPromotionalDiscount && isEditing && loaded && (
            <CardContent className={classes.tabs}>
              <PromotionalDiscountTabs />
            </CardContent>
          )}
        </div>
      </Loadable>
    );
  };
