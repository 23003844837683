import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  FormControl,
  FormProvider,
  Loadable,
  Modal,
  SearchForm,
  Table,
  useForm,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { RadioGroupField, SelectField, TextField } from '@fleet/shared/form';
import { Button } from '@fleet/shared/mui';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { Stop, StopsFilter } from 'dto/stop';
import { useDispatch, useSelector } from 'store/utils';
import {
  addStopsToZone,
  deleteStopsFromZone,
} from 'features/zoneMap/zoneMapActions';
import { stopsLoading } from 'features/loading/loadingSelectors';
import { TableColumns } from '@fleet/shared/index';
import { TransTableHead } from 'i18n/trans/table';
import { usePagination, useRowSelect, useTable } from 'react-table';
import { fetchStops } from 'features/zoneMap/zoneMapService';
import difference from 'lodash/difference';
import type { Config as FormConfig } from 'final-form';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';
import _xor from 'lodash/xor';
import { TransAlert } from 'i18n/trans/alert';
import { Pagination } from '@fleet/shared/dto/pagination';

const useStyles = makeStyles(
  () => ({
    form: {
      '&&': {
        margin: '0 -16px',
      },
    },
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
  }),
  {
    name: 'StopSearch',
  }
);
interface StopSearchProps {
  currentStops: Record<number, boolean>;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const StopSearch: FC<StopSearchProps> = ({
  currentStops,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countries = useClassificationOptions(ClassificationGroup.COUNTRY);
  const loading = useSelector(stopsLoading);
  const [stops, setStops] = useState<Pagination<Stop>>();
  const data = useMemo(() => stops?.items ?? [], [stops]);

  const onSubmit = useCallback<FormConfig<StopsFilter>['onSubmit']>(
    async (values, form) => {
      const required = _xor(form.getRegisteredFields(), [
        'isAssignedToZone',
        'countryId',
      ]);
      if (_isEmpty(_filter(_pick(values, required), Boolean))) {
        return required.reduce(
          (acc, name) => ({
            ...acc,
            [name]: <TransAlert i18nKey="fillAtLeastOne" />,
          }),
          {}
        );
      }
      setStops(await fetchStops({ ...values, offset: 0 }));
    },
    []
  );

  const { form, handleSubmit, dirty, submitting, values } =
    useForm<StopsFilter>({
      initialValues: { isAssignedToZone: true },
      subscription: { dirty: true, submitting: true, values: true },
      onSubmit,
    });

  const handleFilterReset = useCallback(() => form.reset(), [form]);

  const columns = useMemo<TableColumns<Stop>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="stopName" />,
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        accessor: 'countryName',
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        accessor: 'countyName',
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        accessor: 'cityName',
        Header: <TransTableHead i18nKey="city" />,
      },
      {
        accessor: 'streetAddress',
        Header: <TransTableHead i18nKey="street" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams) => {
      setStops(await fetchStops({ ...values, ...paginationParams }));
    },
    [values]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (stops) {
        const { limit = pageSize, offset } = stops;
        return offset / limit;
      }
      return 0;
    },
    [stops]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);
  const table = useTable(
    {
      data,
      columns,
      getRowId,
      initialState: {
        selectedRowIds: currentStops,
      },
      manualPagination: true,
      pageCount: -1,
      total: stops?.totalCount,
      onPageChange: handlePageChange,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds: currentStops,
        pageIndex: getPage(state.pageSize),
      }),
    },
    usePagination,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedStopIds = useMemo(
    () => Object.keys(table.state.selectedRowIds),
    [table.state.selectedRowIds]
  );

  const modifyStopsHandler = useCallback(async () => {
    const currentStopIds = Object.keys(currentStops);
    const added = difference(selectedStopIds, currentStopIds);
    const deleted = difference(currentStopIds, selectedStopIds);

    added.length &&
      (await dispatch(addStopsToZone(added.map(Number))).unwrap());
    deleted.length &&
      (await dispatch(deleteStopsFromZone(deleted.map(Number))).unwrap());
    onConfirm();
  }, [currentStops, selectedStopIds, dispatch, onConfirm]);

  return (
    <Modal
      open={isOpen}
      title={<TransSubtitle i18nKey="addStops" />}
      onClose={onClose}
      actionButton={{
        label: <TransButton i18nKey="save" />,
        onClick: modifyStopsHandler,
      }}
      fullWidth
    >
      <Loadable loading={loading}>
        <SearchForm
          className={classes.form}
          title={<TransSubtitle i18nKey="search" />}
        >
          <FormProvider {...form}>
            <Grid
              container
              columns={5}
              spacing={2}
              component="form"
              onSubmit={handleSubmit}
            >
              <Grid item xs={1}>
                <RadioGroupField
                  name="isAssignedToZone"
                  label={<TransField i18nKey="assigned" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="stopName" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField name="code" label={<TransField i18nKey="code" />} />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="countryId"
                  options={countries}
                  label={<TransField i18nKey="country" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="countyName"
                  label={<TransField i18nKey="county" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="cityName"
                  label={<TransField i18nKey="city" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="streetAddress"
                  label={<TransField i18nKey="street" />}
                />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;" labelPosition="top">
                    <Button
                      variant="text"
                      sx={{ whiteSpace: 'nowrap' }}
                      onClick={handleFilterReset}
                    >
                      <TransButton i18nKey="resetFields" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;" labelPosition="top">
                    <Button type="submit" disabled={!dirty || submitting}>
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </SearchForm>
        <Divider sx={{ mt: 2, mb: 1 }} />
        <Table
          classes={{
            cell: classes.tableCell,
          }}
          table={table}
          caption={
            <Stack direction="row" alignItems="center" sx={{ py: 2 }}>
              <Typography variant="subtitle" fontWeight="700">
                <TransSubtitle i18nKey="stops" />
              </Typography>
              {Boolean(stops?.totalCount) && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 2 }}
                >
                  <TransSubtitle
                    i18nKey="qtyStops"
                    values={{ num: stops?.totalCount }}
                  />
                </Typography>
              )}
            </Stack>
          }
        />
      </Loadable>
    </Modal>
  );
};
