import { useHistory } from 'react-router-dom';
import { FC } from 'react';
import { Layout } from '@fleet/shared/components/Layout';
import { ZoneMapTable } from 'routes/ZoneMap/ZoneMapTable';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { AddButton, CardHeader } from '@fleet/shared';

interface ZoneMapListProps {}

export const ZoneMapList: FC<ZoneMapListProps> = () => {
  const history = useHistory();

  return (
    <Layout
      header={
        <CardHeader title={<TransSubtitle i18nKey="zoneMaps" />}>
          <AddButton onClick={() => history.push('/zone-fares/maps/create')}>
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <ZoneMapTable />
    </Layout>
  );
};
