import type { ChangeEventHandler, FC, FormEventHandler } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useTable, useRowSelect } from 'react-table';
import { makeStyles } from '@mui/styles';
import {
  Button,
  CardHeader,
  Icon,
  Input,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { zoneMapSelector } from 'features/zoneMap/zoneMapSelectors';
import { Zone } from 'dto/zoneMap';
import {
  deleteZones,
  getZoneMap,
  getZoneMapList,
} from 'features/zoneMap/zoneMapActions';
import { filterByKeyword } from 'helpers/filter';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';

interface ZoneTableProps {}

const useStyles = makeStyles(
  (theme) => ({
    form: {
      flex: 1,
    },
    inputSearchAdornment: {
      marginRight: theme.spacing(-1),
    },
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
  }),
  {
    name: 'ZoneTable',
  }
);

export const ZoneTable: FC<ZoneTableProps> = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const classes = useStyles();
  const { id: zoneMapId, zones } = useSelector(zoneMapSelector)!;
  const { pathname } = useLocation();
  const history = useHistory();
  const [data, setData] = useState<Array<Zone>>([]);

  useEffect(() => {
    setData(zones);
  }, [zones]);

  const redirectToZoneCreate = useCallback(
    () => history.push(`${pathname}/zone`),
    [pathname, history]
  );

  const columns = useMemo<TableColumns<Zone>>(
    () => [
      {
        id: 'name',
        accessor: ({ name, id }) => (
          <Link to={`/zone-fares/maps/edit/${zoneMapId}/zone/${id}`}>
            {name}
          </Link>
        ),
        Header: <TransTableHead i18nKey="zoneName" />,
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
    ],
    [zoneMapId]
  );

  type FilterName = 'name' | 'code';
  const [filters, setFilters] = useState<{ [key in FilterName]?: string }>({});

  const handleSetFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const { id: name, value } = event.target;
      setFilters((old) => ({ ...old, [name as FilterName]: value }));
    },
    []
  );
  const handleFilter = useCallback<FormEventHandler>(
    (event) => {
      event.preventDefault();
      const { name, code } = filters;
      setData(
        zones.filter(
          (zone) =>
            (name ? filterByKeyword(name)(zone.name) : true) &&
            (code ? filterByKeyword(code)(zone.code) : true)
        )
      );
    },
    [filters, zones]
  );
  const getRowId = useCallback((row: Zone) => `${row.id}`, []);

  const table = useTable<Zone>(
    {
      data,
      columns,
      getRowId,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedRowIds = useMemo(
    () => Object.keys(table.state.selectedRowIds).map((idx) => +idx),
    [table.state.selectedRowIds]
  );
  const removeSelectedZones = useCallback(async () => {
    await dispatch(deleteZones(selectedRowIds)).unwrap();
    dispatch(getZoneMap(+zoneMapId));
    dispatch(getZoneMapList());

    alert.success(<TransAlert i18nKey="zoneDeleted" />);
  }, [alert, dispatch, selectedRowIds, zoneMapId]);

  return (
    <Table
      classes={{
        cell: classes.tableCell,
      }}
      caption={
        <>
          <Divider />
          <CardHeader
            isLight
            title={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle">
                  <TransSubtitle i18nKey="zones" />
                </Typography>
                <Typography
                  variant="body2"
                  component="span"
                  color="text.secondary"
                >
                  <TransSubtitle
                    i18nKey="zonesQty"
                    values={{ num: data.length }}
                  />
                </Typography>
              </Stack>
            }
            action={
              <Stack direction="row">
                <Button
                  variant="text"
                  startIcon={<Icon name="trash" />}
                  onClick={removeSelectedZones}
                  disabled={!selectedRowIds.length}
                  color="error"
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
                <Button variant="text" startIcon={<Icon name="download" />}>
                  <TransButton i18nKey="export" />
                </Button>
                <Button
                  variant="text"
                  onClick={redirectToZoneCreate}
                  startIcon={<Icon name="add" />}
                >
                  <TransButton i18nKey="add" />
                </Button>
              </Stack>
            }
          />
          <Stack direction="row" sx={{ px: 3, pb: 3 }} spacing={2}>
            <form className={classes.form} onSubmit={handleFilter}>
              <Input
                name="name"
                label={<TransField i18nKey="zoneName" />}
                endAdornment={
                  <InputAdornment
                    position="end"
                    className={classes.inputSearchAdornment}
                  >
                    <IconButton type="submit">
                      <Icon name="search" />
                    </IconButton>
                  </InputAdornment>
                }
                onChange={handleSetFilter}
              />
            </form>
            <form className={classes.form} onSubmit={handleFilter}>
              <Input
                name="code"
                label={<TransField i18nKey="code" />}
                endAdornment={
                  <InputAdornment
                    position="end"
                    className={classes.inputSearchAdornment}
                  >
                    <IconButton type="submit">
                      <Icon name="search" />
                    </IconButton>
                  </InputAdornment>
                }
                onChange={handleSetFilter}
              />
            </form>
          </Stack>
        </>
      }
      table={table}
    />
  );
};
