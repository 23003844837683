import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  CardHeader,
  DrawerForm,
  DrawerFormProps,
  FormProvider,
  Loadable,
  useForm,
  useLoaded,
} from '@fleet/shared';
import {
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FormControl, Icon, Tooltip } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { currentPassengerTypeSelector } from 'features/passengerType/passengerTypeSelectors';
import { passengerTypeLoadingSelector } from 'features/loading/loadingSelectors';
import {
  createPassengerType,
  getPassengerType,
  getPassengerTypes,
  setPassengerType,
  updatePassengerType,
} from 'features/passengerType/passengerTypeActions';
import { getPassengerTypes as getClassificationPassengerTypes } from 'features/classification/classificationActions';
import { useHistory } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { setPassengerTypeDiscount } from 'features/passengerTypeDiscount/passengerTypeDiscountActions';
import { useParams } from 'react-router';
import { PassengerTypeFields } from 'routes/PassengerTypes/PassengerTypeFields';
import { PassengerTypeAccordion } from 'routes/PassengerTypes/Accordion/PassengerTypeAccordion';
import { PassengerType } from 'dto/passengerType';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

interface PassengerTypeDetailsProps {
  id?: string;
}

export const PassengerTypeDetails: FC<PassengerTypeDetailsProps> = () => {
  const passengerType = useSelector(currentPassengerTypeSelector);
  const dispatch = useDispatch();
  const { action, id } = useParams<{ action: string; id?: string }>();
  const loading = useSelector(passengerTypeLoadingSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const loaded = useLoaded(loading);
  const history = useHistory();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );

  const handleGoBack = useCallback(() => {
    history.replace('/passenger-types');
  }, [history]);

  useEffect(() => {
    dispatch(setPassengerType());
    if (isEditing && id) {
      dispatch(getPassengerType(id)).unwrap().catch(handleGoBack);
    }

    return () => {
      dispatch(setPassengerType());
    };
  }, [dispatch, handleGoBack, id, isEditing]);

  const closeDrawer = useCallback(() => {
    history.replace('/passenger-types');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        dispatch(setPassengerTypeDiscount());
        closeDrawer();
      }
    },
    [dispatch, closeDrawer]
  );

  const onSubmit = useCallback(
    async (values: PassengerType) => {
      const res = await dispatch(
        (values.id ? updatePassengerType : createPassengerType)(values)
      ).unwrap();

      if (!values.id) {
        history.replace(`/passenger-types/edit/${res.id}`);
      }

      dispatch(getPassengerTypes());

      if (res.ownerId === currentBusinessEntityId) {
        dispatch(getClassificationPassengerTypes());
      }
    },
    [currentBusinessEntityId, dispatch, history]
  );

  const initialValues = useMemo(
    () => ({
      isActive: false,
      isWithoutSeatReservation: false,
      ...passengerType,
    }),
    [passengerType]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<PassengerType>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <CardHeader
              isLight
              title={
                <Typography variant="subtitle">
                  {isEditing ? (
                    passengerType?.name
                  ) : loading ? (
                    <>&nbsp;</>
                  ) : (
                    <TransTitle i18nKey="passengerType" />
                  )}
                </Typography>
              }
              action={
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              }
            />
            <CardContent>
              <Grid container columns={2} spacing={2}>
                <PassengerTypeFields />
                <Grid item xs="auto" sx={{ ml: 'auto' }}>
                  <Stack direction="row" flexWrap="nowrap">
                    <FormControl label="&nbsp;">
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ whiteSpace: 'nowrap' }}
                        {...(!isEditing && { onClick: closeDrawer })}
                        {...(isEditing && {
                          onClick: handleReset,
                          disabled: !dirty,
                        })}
                      >
                        <TransButton
                          i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                        />
                      </Button>
                    </FormControl>
                    <FormControl label="&nbsp;">
                      <Button
                        type="submit"
                        variant="contained"
                        icon={id ? 'check' : 'plus'}
                        disabled={submitting}
                      >
                        <TransButton i18nKey={id ? 'save' : 'create'} />
                      </Button>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
            {loaded && <PassengerTypeAccordion />}
          </form>
        </FormProvider>
      </Loadable>
    </DrawerForm>
  );
};
