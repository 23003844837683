import type { FC } from 'react';
import {
  FormProvider,
  Table,
  TableColumns,
  useForm,
  useFormTableControls,
} from '@fleet/shared';
import { useCallback, useMemo } from 'react';
import { TransTableHead } from 'i18n/trans/table';
import {
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { useRowSelect } from 'react-table';
import { useDispatch } from 'store/utils';
import { Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { Infos, TABLE_TYPE } from 'dto/vouchersTemplate';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  createTermsAndConditions,
  createVoucherInfo,
  deleteTermsAndConditions,
  deleteVoucherInfo,
  getVoucherTemplate,
  updateTermsAndConditions,
  updateVoucherInfo,
} from 'features/vouchersTemplate/vouchersTemplateActions';
import { useParams } from 'react-router-dom';
import { validate } from '@fleet/shared/form/validate';

interface VouchersTemplateInfoTableProps {
  data: Array<Infos>;
  type: string;
}

export const TermsAndInfoTable: FC<VouchersTemplateInfoTableProps> = ({
  data,
  type,
}) => {
  const dispatch = useDispatch();
  const languages = useClassificationOptions(ClassificationGroup.CULTURE);
  const { id } = useParams<{ id?: string }>();
  const { form } = useForm<{ rows: Array<Infos> }>({
    initialValues: {
      rows: data,
    },
  });

  const columns: TableColumns<Infos> = useMemo(
    () => [
      {
        id: 'id',
      },
      {
        id: 'language.id',
        accessor: ({ language }) => language?.id,
        type: 'select',
        editableProps: {
          options: languages,
          validate: validate.required(),
        },
        Header: <TransTableHead i18nKey="language" />,
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        editableProps: {
          validate: validate.required(),
        },
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
        editableProps: {
          validate: validate.required(),
        },
      },
    ],
    [languages]
  );
  const formTable = useFormTable<Infos>(
    {
      data,
      columns,
      form,
      initialState: { pageSize: 20, hiddenColumns: ['id'] },
      onRowUpdate: useCallback(
        async (value) => {
          const mutatedValue = {
            ...value,
            languageId: value.language.id,
          };
          value.id
            ? await dispatch(
                type === TABLE_TYPE.INFO
                  ? updateVoucherInfo(mutatedValue)
                  : updateTermsAndConditions(mutatedValue)
              )
            : await dispatch(
                type === TABLE_TYPE.INFO
                  ? createVoucherInfo(mutatedValue)
                  : createTermsAndConditions(mutatedValue)
              );
          await dispatch(getVoucherTemplate(id!));
        },
        [dispatch, id, type]
      ),
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<Infos>) => {
      await Promise.all(
        payload.map((row: Infos) =>
          dispatch(
            type === TABLE_TYPE.INFO
              ? deleteVoucherInfo(row.id)
              : deleteTermsAndConditions(row.id)
          )
        )
      );
      await dispatch(getVoucherTemplate(id!));
    },
    [dispatch, id, type]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table: formTable,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Stack direction="row" alignItems="center" justifyContent="end">
        <div>
          <Button
            startIcon={<Icon name="trash" />}
            onClick={removeSelectedRows}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button startIcon={<Icon name="plus" />} onClick={addRow}>
            <TransButton i18nKey="addNew" />
          </Button>
        </div>
      </Stack>
      <Table
        table={formTable}
        getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
      />
    </FormProvider>
  );
};
