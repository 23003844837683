import { useHistory, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { ZoneTable } from 'routes/ZoneMap/ZoneTable';
import { CardContent, Grid, Stack, Typography } from '@mui/material';
import {
  Button,
  CardHeader,
  FormProvider,
  Icon,
  Layout,
  Loadable,
  makeClassificationOptions,
  PeriodField,
  useForm,
} from '@fleet/shared';
import { SelectField, TextField } from '@fleet/shared/form';
import { useDispatch, useSelector } from 'store/utils';
import {
  clearCurrentZoneMap,
  createZoneMap,
  getZoneMap,
  getZoneMapList,
  updateZoneMap,
} from 'features/zoneMap/zoneMapActions';
import { zoneMapSelector } from 'features/zoneMap/zoneMapSelectors';
import { ZoneMapPayload } from 'dto/zoneMap';
import { zoneMapLoading } from 'features/loading/loadingSelectors';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { SelectOwnerField } from 'components/SelectOwnerField';
import { fetchFareModels } from 'features/fareModel/fareModelService';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';

interface ZoneMapFormProps {}

export const ZoneMapForm: FC<ZoneMapFormProps> = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const loading = useSelector(zoneMapLoading);
  const current = useSelector(zoneMapSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const [fareModelOptions, setFareModelOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  useEffect(() => {
    if (id) {
      dispatch(getZoneMap(+id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentZoneMap());
    };
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: ZoneMapPayload) => {
      const { id } = await dispatch(
        (values.id ? updateZoneMap : createZoneMap)(values)
      ).unwrap();

      history.replace(`/zone-fares/maps/edit/${id}`);

      alert.success(
        <TransAlert
          i18nKey="zoneMapCreated"
          {...(id && { i18nKey: 'zoneMapUpdated' })}
        />
      );

      dispatch(getZoneMapList());
    },
    [alert, dispatch, history]
  );

  const initialValues = useMemo<Partial<ZoneMapPayload>>(
    () => ({
      zones: [],
      validity: {
        from: '',
        to: '',
        timeZoneFrom: 'UTC',
        timeZoneTo: 'UTC',
      },
      ownerId: currentBusinessEntityId,
      ...(current && {
        ...current,
        ownerId: current.owner?.id,
        fareModelId: current.fareModel?.id,
      }),
    }),
    [current, currentBusinessEntityId]
  );

  const { form, handleSubmit, values } = useForm<ZoneMapPayload>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const fetchFareModelOptions = useCallback(async (ownerId: number) => {
    setFareModelOptions(
      makeClassificationOptions(await fetchFareModels(ownerId))
    );
  }, []);

  useEffect(() => {
    if (values.ownerId) {
      fetchFareModelOptions(values.ownerId);
    }
  }, [fetchFareModelOptions, values.ownerId]);

  const redirectBack = useCallback(
    () => history.push('/zone-fares/maps'),
    [history]
  );

  return (
    <Layout
      header={
        <CardHeader
          title={<TransSubtitle i18nKey={id ? 'zoneMaps' : 'newZoneMap'} />}
        />
      }
    >
      <Loadable loading={loading}>
        <Stack>
          <CardContent
            sx={{ p: 2, pt: 1.5 }}
            component="form"
            onSubmit={handleSubmit}
          >
            <FormProvider {...form}>
              <Grid container columns={5} spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={5} sx={{ mb: 0.5 }}>
                  <Typography variant="subtitle">
                    <TransSubtitle i18nKey="details" />
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="name"
                    label={<TransField i18nKey="mapName" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectOwnerField disabled required />
                </Grid>
                <PeriodField
                  from={{
                    name: 'validity.from',
                    label: <TransField i18nKey="validFrom" />,
                    required: true,
                  }}
                  to={{
                    name: 'validity.to',
                    label: <TransField i18nKey="validTo" />,
                    required: true,
                  }}
                />
                <Grid item xs={1}>
                  <TextField
                    name="code"
                    label={<TransField i18nKey="code" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="fareModelId"
                    options={fareModelOptions}
                    label={<TransField i18nKey="fareModel" />}
                    required
                  />
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end">
                {id ? (
                  <>
                    <Button onClick={() => form.reset()} variant="text">
                      <TransButton i18nKey="resetChanges" />
                    </Button>
                    <Button type="submit" startIcon={<Icon name="check" />}>
                      <TransButton i18nKey="save" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={redirectBack} variant="text">
                      <TransButton i18nKey="cancel" />
                    </Button>
                    <Button type="submit" startIcon={<Icon name="plus" />}>
                      <TransButton i18nKey="create" />
                    </Button>
                  </>
                )}
              </Stack>
            </FormProvider>
          </CardContent>
          {id && Boolean(current?.zones) && <ZoneTable />}
        </Stack>
      </Loadable>
    </Layout>
  );
};
