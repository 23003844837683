import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { PassengerTypeValidityCriteria } from 'dto/passengerType';
import { TransTableHead } from 'i18n/trans/table';
import { Button, Stack } from '@mui/material';
import { useAlert } from 'react-alert';
import { useRowSelect } from 'react-table';
import { TransButton } from 'i18n/trans/button';
import {
  FormField,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  TextField,
  api,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
  ConfirmDeleteModal,
  useModal,
  makeClassificationOptions,
} from '@fleet/shared';
import { currentPassengerTypeSelector } from 'features/passengerType/passengerTypeSelectors';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  deletePassengerTypeValidityCriteria,
  getPassengerType,
  updateOrCreatePassengerTypeValidityCriteria,
} from 'features/passengerType/passengerTypeActions';
import { TransAlert } from 'i18n/trans/alert';
import { renderToString } from 'react-dom/server';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';

export const PassengerTypeValidityCriterias: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentPassengerType = useSelector(currentPassengerTypeSelector);
  const validityCriterias = useMemo(
    () => currentPassengerType?.validityCriterias ?? [],
    [currentPassengerType?.validityCriterias]
  );
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);
  const [cardTypeOptions, setCardTypeOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const { open: isOpen, onOpen, onClose } = useModal();

  useEffect(() => {
    (async () => {
      const { data } = await api.get<{ items: Array<Classifier> }>(
        `/passenger-types/${currentPassengerType?.id}/card-types`
      );
      setCardTypeOptions(makeClassificationOptions(data.items));
    })();
  }, [currentPassengerType?.id]);

  const renderAgeCell = useCallback(({ cell: { row, column } }) => {
    const { state, original } = row;

    if (state.editable) {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormField
            name={`rows[${row.id}].${column.id}From`}
            required
            render={({ input }) => <TextField {...input} type="number" />}
          />
          <span>-</span>
          <FormField
            name={`rows[${row.id}].${column.id}To`}
            required
            render={({ input }) => <TextField {...input} type="number" />}
          />
        </Stack>
      );
    }

    return <div>{`${original.ageFrom ?? ''} - ${original.ageTo ?? ''}`}</div>;
  }, []);

  const columns: TableColumns<PassengerTypeValidityCriteria> = useMemo(
    () => [
      {
        id: 'age',
        Header: <TransTableHead i18nKey="ageFromTo" />,
        Cell: renderAgeCell,
      },
      {
        id: 'cardType.id',
        Header: <TransTableHead i18nKey="withCardType" />,
        accessor: ({ cardType }) => cardType?.id,
        type: 'select',
        editableProps: {
          required: false,
          options: cardTypeOptions,
        },
      },
      {
        id: 'departureCountry.id',
        Header: <TransTableHead i18nKey="countryFrom" />,
        accessor: ({ departureCountry }) => departureCountry?.id,
        type: 'select',
        editableProps: {
          required: false,
          validate: (value, row) =>
            !value && row?.arrivalCountry?.id
              ? renderToString(<TransField i18nKey="required" />)
              : undefined,
          options: countryOptions,
        },
      },
      {
        id: 'arrivalCountry.id',
        Header: <TransTableHead i18nKey="countryTo" />,
        accessor: ({ arrivalCountry }) => arrivalCountry?.id,
        type: 'select',
        editableProps: {
          required: false,
          validate: (value, row) =>
            !value && row?.departureCountry?.id
              ? renderToString(<TransField i18nKey="required" />)
              : undefined,
          options: countryOptions,
        },
      },
    ],
    [cardTypeOptions, countryOptions, renderAgeCell]
  );

  const { form } = useForm<{ rows: Array<PassengerTypeValidityCriteria> }>({
    initialValues: {
      rows: validityCriterias,
    },
  });

  const handleRowUpdate = useCallback(
    async ({
      cardType,
      arrivalCountry,
      departureCountry,
      ...rest
    }: PassengerTypeValidityCriteria) => {
      await dispatch(
        updateOrCreatePassengerTypeValidityCriteria({
          cardTypeId: cardType?.id,
          arrivalCountryId: arrivalCountry?.id,
          departureCountryId: departureCountry?.id,
          ...rest,
        })
      ).unwrap();
      dispatch(getPassengerType(`${currentPassengerType?.id}`));
    },
    [dispatch, currentPassengerType?.id]
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<PassengerTypeValidityCriteria>) => {
      await Promise.all(
        rows.map((row) =>
          dispatch(deletePassengerTypeValidityCriteria(row.id!)).unwrap()
        )
      );
      alert.success(
        <TransAlert i18nKey="passengerTypeValidityCriteriaDeleted" />
      );

      onClose();
      dispatch(getPassengerType(`${currentPassengerType?.id}`));
    },
    [alert, onClose, dispatch, currentPassengerType?.id]
  );

  const table = useFormTable<PassengerTypeValidityCriteria>(
    {
      data: validityCriterias,
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  const shouldDisableDeletion = useCallback(() => {
    if (!Object.keys(table.state.selectedRowIds).length) {
      return true;
    }
    return !!(
      (table.rows.length === 1 && currentPassengerType?.isActive) ||
      (Object.keys(table.state.selectedRowIds).length === table.rows.length &&
        currentPassengerType?.isActive)
    );
  }, [
    currentPassengerType?.isActive,
    table.rows.length,
    table.state.selectedRowIds,
  ]);

  return (
    <FormProvider {...form}>
      <Table
        caption={
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ mb: 1, gap: '10px' }}
            >
              <Button
                startIcon={<Icon name="delete" />}
                onClick={onOpen}
                disabled={shouldDisableDeletion()}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <ConfirmDeleteModal
                handleDelete={removeSelectedRows}
                title={
                  <TransModal i18nKey="passengerTypeValidityDeletionTitle" />
                }
                description={
                  <TransModal i18nKey="passengerTypeValidityDeletionDescription" />
                }
                isOpen={isOpen}
                onClose={onClose}
              />
              <Button startIcon={<Icon name="plus" />} onClick={addRow}>
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
        }
        getTableProps={{ sx: { tableLayout: 'fixed' } }}
        getHeaderProps={{ style: { backgroundColor: 'white' } }}
        table={table}
      />
    </FormProvider>
  );
};
