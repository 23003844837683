import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { getFareModelOriginDestinationFares } from 'features/fareModel/fareModelActions';
import { SelectField } from '@fleet/shared/form';
import { Divider, Grid, Typography } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FareModelStopsSelectValue } from 'routes/FareModels/FareModelStopsSelect/FareModelStopsSelect';
import { useDispatch } from 'store/utils';
import { SelectCurrencyField } from 'components/SelectCurrencyField';
import {
  FareModelFilterForm,
  FareModelFilterFormProps,
} from 'routes/FareModels/FareModelFilterForm';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),
    },
  }),
  {
    name: 'FareModelStopsFilterForm',
  }
);

interface FareModelStopsFilterFormProps {}

interface FormValues {
  originStops: FareModelStopsSelectValue;
  destinationStops: FareModelStopsSelectValue;
  currencyId?: string;
  fareCategoryId?: string;
}

export const FareModelStopsFilterForm: FC<FareModelStopsFilterFormProps> =
  () => {
    const initialValues = useMemo(
      () => ({
        // TODO, DEV mode only, remove if unnecessary
        ...(process.env.NODE_ENV === 'development' && {
          currencyId: 'CURRENCY.EUR',
        }),
      }),
      []
    );

    const dispatch = useDispatch();
    const onSubmit = useCallback<
      FareModelFilterFormProps<FormValues>['onSubmit']
    >(
      async ({ originStopIds, destinationStopIds, ...values }) => {
        await dispatch(
          getFareModelOriginDestinationFares({
            originStopIds,
            destinationStopIds,
            ...values,
          })
        );
      },
      [dispatch]
    );

    const categoryOptions = useClassificationOptions(
      ClassificationGroup.FARE_CATEGORY
    );
    const classes = useStyles();

    return (
      <>
        <FareModelFilterForm<FormValues>
          initialValues={initialValues}
          onSubmit={onSubmit}
          className={classes.root}
        >
          <Grid item xs={12}>
            <Divider sx={{ mb: 2, ml: -3, mr: -3 }} />
            <Typography variant="subtitle" fontWeight="700">
              <TransSubtitle i18nKey="filterFares" />
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <SelectCurrencyField required />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              label={<TransField i18nKey="category" />}
              name="fareCategoryId"
              options={categoryOptions}
              showEmptyOption
            />
          </Grid>
        </FareModelFilterForm>
      </>
    );
  };
