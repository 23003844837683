import { Classifier } from '@fleet/shared/dto/classifier';
import { Validity } from 'dto/common';

export interface VouchersTemplateBase extends Classifier<number> {
  priority: number;
  isActive: boolean;
  createdVoucherCount: number;
  owner: Classifier<number>;
  voucherType: Classifier;
  voucherUsageType: Classifier;
}

export interface PriorityVouchersTemplate {
  priority: number;
  owner: { id: number; name: string };
}

export interface VouchersTemplateSearchFormValues {
  ownerId: number;
  name: string;
  voucherTypeId: string;
  validFrom: string;
  validTo: string;
  hasVouchers: boolean;
  isActive: boolean;
}

export interface VoucherTemplate extends Classifier<number> {
  code: string;
  description: string;
  vatRate: number;
  priority: number;
  validity: Validity;
  canValueBeSetOnCreation: boolean;
  validityPeriod: number;
  maxNoOfPayments: number;
  maxNoOfTotalTickets: number;
  minimumAmountLeftToPay: number;
  minimumPercentageLeftToPay: number;
  allowedTransactionTypes: Array<Classifier>;
  printoutTheme: Classifier;
  owner: Classifier<number>;
  vatDirective: Classifier<number>;
  voucherType: Classifier;
  voucherUsageType: Classifier;
  periodType: Classifier;
  unitPrices: Array<UnitPrice>;
  infos: Array<Infos>;
  termsAndConditions: Array<Infos>;
}

export interface VoucherTemplatePayload
  extends Omit<
    VoucherTemplate,
    | 'owner'
    | 'voucherType'
    | 'printoutTheme'
    | 'periodType'
    | 'voucherUsageType'
  > {
  ownerId: number;
  voucherTypeId: string;
  voucherUsageTypeId: string;
  printoutThemeId: string;
  periodTypeId: string;
}

export interface Infos extends Classifier<number> {
  description: string;
  language: Classifier;
}

export interface UnitPrice {
  id: number;
  unitPrice: number;
  validity: Validity;
  currency: Classifier;
  value: number;
}

export enum VOUCHER_TYPE {
  COMPENSATION = 'VOUCHER_TYPE.COMPENSATION',
  LOYALTY = 'VOUCHER_TYPE.LOYALTY',
  REFUND = 'VOUCHER_TYPE.REFUND',
  SOLD = 'VOUCHER_TYPE.SOLD',
}

export enum TABLE_TYPE {
  TERMSANDCONDITIONS = 'TermsAndConditions',
  INFO = 'INFO',
}
