import { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Link as MuiLink, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { Row, useRowSelect, useTable } from 'react-table';
import { useIndeterminateRowSelectCheckbox } from '@fleet/shared/hooks';
import {
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  useRowActive,
} from '@fleet/shared';
import { CoTaxationGroup } from 'dto/coTaxationGroup';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { getCoTaxationGroups } from 'features/coTaxationGroup/coTaxationGroupActions';
import { coTaxationGroupsLoading } from 'features/loading/loadingSelectors';
import { coTaxationGroupsSelector } from 'features/coTaxationGroup/coTaxationGroupSelectors';

export const CoTaxationGroupTable: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const coTaxationGroups = useSelector(coTaxationGroupsSelector);
  const listLoading = useSelector(coTaxationGroupsLoading);
  const data = useMemo(() => coTaxationGroups ?? [], [coTaxationGroups]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCoTaxationGroups());
  }, [dispatch]);

  const link = useCallback(
    (row: Row<CoTaxationGroup>) =>
      `/co-taxation-groups/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<CoTaxationGroup>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="groupName" />,
        Cell: ({ row, value }) => (
          <MuiLink underline="none" component={Link} to={link(row)}>
            {value}
          </MuiLink>
        ),
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        id: 'contacts',
        accessor: ({ contacts }) =>
          contacts?.map(({ name }) => name).join(', '),
        Header: <TransTableHead i18nKey="carriers" />,
      },
    ],
    [link]
  );

  const table = useTable<CoTaxationGroup>(
    {
      data,
      columns,
    },
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const { activeFlatRow, resetRowActive } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  return (
    <Loadable loading={listLoading}>
      <SearchResult
        results={data.length}
        loading={listLoading}
        i18nKey="noDataAdded"
      >
        <Table
          caption={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height={56}
            >
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', pl: 2 }}
              >
                <TransSubtitle
                  i18nKey="coTaxationGroupsQty"
                  values={{ count: data.length }}
                  tOptions={{ postProcess: 'interval' }}
                />
              </Typography>
              <Button startIcon={<Icon name="delete" />} disabled>
                <TransButton i18nKey="delete" />
              </Button>
            </Stack>
          }
          table={table}
        />
      </SearchResult>
    </Loadable>
  );
};
