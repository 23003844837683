import { AddButton, CardHeader, Layout } from '@fleet/shared';
import { TransNav } from 'i18n/trans/nav';
import { PromotionalDiscountsTable } from 'routes/PromotionalDiscounts/PromotionalDiscountsTable';
import { useHistory } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';

export default function PromotionalDiscounts() {
  const history = useHistory();

  return (
    <Layout
      header={
        <CardHeader title={<TransNav i18nKey="promotionalDiscounts" />}>
          <AddButton
            onClick={() => history.push('/promotional-discounts/create')}
          >
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <PromotionalDiscountsTable />
    </Layout>
  );
}
