import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import {
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { PassengerTypeTranslation } from 'dto/passengerType';
import { useDispatch, useSelector } from 'store/utils';
import { currentPassengerTypeSelector } from 'features/passengerType/passengerTypeSelectors';
import { useRowSelect } from 'react-table';
import { Button, Stack } from '@mui/material';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  deletePassengerTypeTranslation,
  getPassengerType,
  updateOrCreatePassengerTypeTranslation,
} from 'features/passengerType/passengerTypeActions';
import { TransModal } from 'i18n/trans/modal';

export const PassengerTypeTranslations: FC = () => {
  const currentPassengerType = useSelector(currentPassengerTypeSelector);
  const translations = useMemo(
    () => currentPassengerType?.translations ?? [],
    [currentPassengerType?.translations]
  );
  const cultures = useClassificationMap(ClassificationGroup.CULTURE);
  const cultureOptions = useClassificationOptions(ClassificationGroup.CULTURE);
  const { open: isOpen, onOpen, onClose } = useModal();
  const usedTranslations = useMemo(
    () => translations.map(({ languageId }) => languageId),
    [translations]
  );
  const availableCultureOptions = useMemo(
    () =>
      cultureOptions.filter(({ value }) => !usedTranslations.includes(value)),
    [cultureOptions, usedTranslations]
  );

  const columns: TableColumns<PassengerTypeTranslation> = useMemo(
    () => [
      {
        Header: <TransTableHead i18nKey="language" />,
        accessor: 'languageId',
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: cultures.get(value) },
            ...availableCultureOptions,
          ].filter(Boolean),
        }),
      },
      {
        Header: <TransTableHead i18nKey="name" />,
        accessor: 'name',
      },
      {
        Header: <TransTableHead i18nKey="shortDescription" />,
        accessor: 'shortDescription',
        editableProps: {
          required: false,
        },
      },
      {
        Header: <TransTableHead i18nKey="description" />,
        accessor: 'description',
        editableProps: {
          required: false,
        },
      },
    ],
    [availableCultureOptions, cultures]
  );
  const { form } = useForm<{ rows: Array<PassengerTypeTranslation> }>({
    initialValues: {
      rows: translations,
    },
  });

  const dispatch = useDispatch();
  const alert = useAlert();
  const handleRowUpdate = useCallback(
    async (payload: PassengerTypeTranslation) => {
      await dispatch(updateOrCreatePassengerTypeTranslation(payload)).unwrap();
      dispatch(getPassengerType(`${currentPassengerType?.id}`));
    },
    [dispatch, currentPassengerType?.id]
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<PassengerTypeTranslation>) => {
      await Promise.all(
        rows.map((row) =>
          dispatch(deletePassengerTypeTranslation(row.id!)).unwrap()
        )
      );
      alert.success(<TransAlert i18nKey="passengerTypeTranslationsDeleted" />);

      onClose();
      dispatch(getPassengerType(`${currentPassengerType?.id}`));
    },
    [alert, onClose, dispatch, currentPassengerType?.id]
  );

  const table = useFormTable<PassengerTypeTranslation>(
    {
      data: translations,
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <FormProvider {...form}>
      <Table
        caption={
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ mb: 1, gap: '10px' }}
            >
              <Button
                startIcon={<Icon name="delete" />}
                onClick={onOpen}
                disabled={!Object.keys(table.state.selectedRowIds).length}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <ConfirmDeleteModal
                handleDelete={removeSelectedRows}
                title={
                  <TransModal i18nKey="passengerTypeTranslationDeletionTitle" />
                }
                description={
                  <TransModal i18nKey="passengerTypeTranslationDeletionDescription" />
                }
                isOpen={isOpen}
                onClose={onClose}
              />
              <Button startIcon={<Icon name="plus" />} onClick={addRow}>
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
        }
        getTableProps={{ sx: { tableLayout: 'fixed' } }}
        getHeaderProps={{ style: { backgroundColor: 'white' } }}
        table={table}
      />
    </FormProvider>
  );
};
