import type { ChangeEvent, FC, MouseEventHandler } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  deletePriceListPrice,
  getPriceList,
  updatePriceListPrice,
} from 'features/zonePriceList/zonePriceListActions';
import { PriceDetailsTabPricesContext } from 'routes/ZonePriceList/context/PriceDetailsTabPricesContext';
import { useDispatch } from 'store/utils';
import debounce from 'lodash/debounce';
import { toNumber } from 'helpers/toNumber';

interface PriceDetailsTabPricesTextFieldProps {
  id: number;
  rowIndex: number;
  colIndex: number;
}

export const PriceDetailsTabPricesTextField: FC<PriceDetailsTabPricesTextFieldProps> =
  ({ id, rowIndex, colIndex }) => {
    const { prices, getPrice, changePrice } = useContext(
      PriceDetailsTabPricesContext
    );
    const { price = '', selected } =
      prices?.get(`${rowIndex}-${colIndex}`) ?? {};
    const [value, setValue] = useState<string>(price);
    useEffect(() => {
      setValue(toNumber(price) ? price : '');
    }, [price]);

    const dispatch = useDispatch();
    const changeHandler = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const value = toNumber(event.target.value);
        changePrice(colIndex, rowIndex, `${value}`);
        const price = getPrice(colIndex, rowIndex);
        await dispatch(
          (value ? updatePriceListPrice : deletePriceListPrice)({
            id: `${id}`,
            price,
          })
        ).unwrap();
        await dispatch(getPriceList(id));
      },
      [changePrice, colIndex, dispatch, getPrice, id, rowIndex]
    );

    const debouncedEventHandler = useMemo(
      () => debounce(changeHandler, 500),
      [changeHandler]
    );

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        debouncedEventHandler(event);
      },
      [debouncedEventHandler]
    );

    const handleFocus = useCallback<MouseEventHandler<HTMLInputElement>>(
      (event) => {
        event.stopPropagation();
      },
      []
    );

    return (
      <TextField
        variant="outlined"
        sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
        value={value}
        onChange={handleChange}
        onClick={handleFocus}
        focused={selected}
      />
    );
  };
