import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRowSelect } from 'react-table';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  api,
  useForm,
  useIndeterminateRowSelectCheckbox,
  makeClassificationOptions,
} from '@fleet/shared';
import { Button, Stack } from '@mui/material';
import { useFormTable, useRowEditActions } from '@fleet/shared/hooks';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { currentCoTaxationGroupSelector } from 'features/coTaxationGroup/coTaxationGroupSelectors';
import { useFormTableControls } from '@fleet/shared/hooks/useFormTableControls';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  getCoTaxationGroup,
  getCoTaxationGroups,
} from 'features/coTaxationGroup/coTaxationGroupActions';
import { Option } from '@fleet/shared/dto/option';
import { fetchBusinessEntities } from 'features/classification/classificationService';
import sortBy from 'lodash/sortBy';

const conf = { baseURL: process.env.REACT_APP_API_OM };

interface CoTaxationGroupCarriersProps {}

export const CoTaxationGroupCarriers: FC<CoTaxationGroupCarriersProps> = () => {
  const dispatch = useDispatch();
  const current = useSelector(currentCoTaxationGroupSelector)!;
  const [carrierOptions, setCarrierOptions] = useState<Option<number>[]>([]);
  const data = useMemo(() => current?.contacts ?? [], [current]);

  const fetchCarrierOptions = useCallback(
    async () =>
      setCarrierOptions(
        sortBy(
          makeClassificationOptions(
            await fetchBusinessEntities({ RoleId: 'CONTACT_ROLE.BUS_COMPANY' })
          ),
          'label'
        )
      ),
    []
  );

  useEffect(() => {
    fetchCarrierOptions();
  }, [fetchCarrierOptions]);

  const { form } = useForm<{ rows: Array<Classifier<number>> }>({
    initialValues: {
      rows: data,
    },
  });

  const columns = useMemo<TableColumns<Classifier<number>>>(
    () => [
      {
        accessor: 'id',
        Header: <TransTableHead i18nKey="carrier" />,
        type: 'select',
        editableProps: {
          options: carrierOptions,
        },
      },
    ],
    [carrierOptions]
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<Classifier<number>>) => {
      await Promise.all(
        rows.map((row) =>
          api.delete(
            `/co-taxation-groups/${current!.id}/organizations/${row.id}`,
            conf
          )
        )
      );

      dispatch(getCoTaxationGroups());
      dispatch(getCoTaxationGroup(current.id));
    },
    [current, dispatch]
  );

  const onRowUpdate = useCallback(
    async (value: Classifier<number>) => {
      await api.post(
        `/co-taxation-groups/${current!.id}/organizations`,
        { organizationId: value.id },
        conf
      );

      dispatch(getCoTaxationGroups());
      dispatch(getCoTaxationGroup(current.id));
    },
    [current, dispatch]
  );

  const table = useFormTable<Classifier<number>>(
    {
      data,
      columns,
      form,
      onRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Button
          startIcon={<Icon name="delete" />}
          onClick={removeSelectedRows}
          disabled={!Object.keys(table.state.selectedRowIds).length}
          color="error"
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>

        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={addRow}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        table={table}
        getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
      />
    </FormProvider>
  );
};
