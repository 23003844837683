import type { FC } from 'react';
import { useCallback } from 'react';
import { Button } from '@mui/material';
import { Icon, Modal, formSubmit, useForm } from '@fleet/shared';
import { useDispatch } from 'store/utils';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { useModal } from '@fleet/shared/hooks';
import { TransModal } from 'i18n/trans/modal';
import { TransAlert } from 'i18n/trans/alert';
import {
  deletePriceList,
  getPriceLists,
} from 'features/zonePriceList/zonePriceListActions';

interface PriceDeleteModalProps {
  id: number;
}

export const PriceDeleteModal: FC<PriceDeleteModalProps> = ({ id }) => {
  const { open: isOpen, onOpen, onClose } = useModal();
  const alert = useAlert();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    () =>
      formSubmit(async () => {
        await dispatch(deletePriceList(id)).unwrap();
        alert.success(<TransAlert i18nKey="priceDeleted" />);
        await dispatch(getPriceLists());
      }),
    [alert, id, dispatch]
  );
  const { handleSubmit, submitting } = useForm({
    onSubmit,
    subscription: { submitting: true },
  });
  return (
    <>
      <Button
        startIcon={<Icon name="trash" size={16} />}
        size="small"
        color="error"
        onClick={onOpen}
      >
        <TransButton i18nKey="delete" />
      </Button>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={<TransModal i18nKey="deletePrice" />}
        actionButton={
          <form onSubmit={handleSubmit}>
            <Button
              type="submit"
              startIcon={<Icon name="trash" size={16} />}
              variant="contained"
              color="error"
              disabled={submitting}
            >
              <TransButton i18nKey="confirm" />
            </Button>
          </form>
        }
        maxWidth="xs"
      />
    </>
  );
};
