import { FC, useCallback, useMemo } from 'react';
import {
  TableColumns,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { validate } from '@fleet/shared/form/validate';
import {
  createOrUpdatePromotionalDiscountDepartureDay,
  removePromotionalDiscountDepartureDays,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';

interface PromotionalDiscountTabDaysPriorToDepartureProps {}

type Entity = PromotionalDiscount['departureDays'][number];

export const PromotionalDiscountTabDaysPriorToDeparture: FC<PromotionalDiscountTabDaysPriorToDepartureProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { departureDays } = currentDiscount;
    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          accessor: 'daysBeforeDepartureFrom',
          Header: <TransTableHead i18nKey="from" />,
          editableProps: {
            validate: validate.required(),
          },
        },
        {
          accessor: 'daysBeforeDepartureTo',
          editableProps: {
            validate: validate.required(),
          },
          Header: <TransTableHead i18nKey="to" />,
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'departureDays',
            }),
          },
        },
      ],
      [currentDiscount]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({ discountPercentageAmount, ...rest }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountDepartureDay({
            ...rest,
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountDepartureDays(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={departureDays.map((day) => ({
          ...day,
          discountPercentageAmount: day.discountPercentageAmount
            ? decimalToPercentage(day.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
