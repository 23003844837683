import type { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { PassengerTypeDetails } from 'routes/PassengerTypes/PassengerTypeDetails';
import { useSelector } from 'store/utils';
import { passengerTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { Layout } from '@fleet/shared/components/Layout';
import { AddButton, CardHeader, Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { PassengerTypeTable } from './PassengerTypeTable';

interface PassengerTypesProps {}

export const PassengerTypes: FC<PassengerTypesProps> = () => {
  const history = useHistory();
  const loading = useSelector(passengerTypesLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="passengerTypes" />}>
            <AddButton onClick={() => history.push('/passenger-types/create')}>
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/passenger-types/:action(create|edit)/:id?"
              component={PassengerTypeDetails}
              exact
            />
          </Switch>
        }
      >
        <PassengerTypeTable />
      </Layout>
    </Loadable>
  );
};
