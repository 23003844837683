import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Accordion,
  AccordionPanel,
  CardHeader,
  DrawerForm,
  FormProvider,
  Icon,
  Loadable,
  useForm,
} from '@fleet/shared';
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  createVoucherTemplate,
  deleteVoucherTemplate,
  getVoucherTemplate,
  getVoucherTemplates,
  setVoucherTemplate,
  updateVoucherTemplate,
} from 'features/vouchersTemplate/vouchersTemplateActions';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  currentVoucherTemplate,
  vouchersTemplateListSelector,
} from 'features/vouchersTemplate/vouchersTemplateSelector';
import { VouchersTemplateForm } from 'routes/VouchersTemplate/VouchersTemplateForm';
import {
  TABLE_TYPE,
  VOUCHER_TYPE,
  VoucherTemplatePayload,
} from 'dto/vouchersTemplate';
import { TermsAndInfoTable } from 'routes/VouchersTemplate/accordions/TermsAndInfoTable';
import { UnitPriceTable } from 'routes/VouchersTemplate/accordions/UnitPriceTable';
import { vouchersTemplateLoadingSelector } from 'features/loading/loadingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import _omit from 'lodash/omit';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { Tooltip } from '@fleet/shared/mui';

export interface VouchersTemplateDetailsForm {
  isCreation: boolean;
  isEdit: boolean;
}

export const VouchersTemplateDetailsForm: FC<VouchersTemplateDetailsForm> = ({
  isCreation,
  isEdit,
}) => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(vouchersTemplateLoadingSelector);
  const alert = useAlert();
  const currentTemplate = useSelector(currentVoucherTemplate);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const vouchersTemplateList = useSelector(vouchersTemplateListSelector);

  const isRelatedToVouchers = () => {
    if (id) {
      const voucher = vouchersTemplateList.find((voucher) => {
        return voucher.id.toString() === id;
      });
      return voucher?.createdVoucherCount !== 0;
    }
  };

  const onSubmit = useCallback(
    async (values: VoucherTemplatePayload) => {
      if (isEdit) {
        await dispatch(updateVoucherTemplate(values)).unwrap();
        alert.success(<TransAlert i18nKey={'voucherTemplateUpdate'} />);
      }
      if (isCreation) {
        const voucherTemplate = await dispatch(
          createVoucherTemplate(values)
        ).unwrap();
        alert.success(<TransAlert i18nKey={'voucherTemplateAdd'} />);
        history.push(`/vouchers-template/edit/${voucherTemplate?.id}`);
      }
    },
    [alert, dispatch, history, isEdit, isCreation]
  );

  const initialValues = useMemo(
    () => ({
      ownerId: currentBusinessEntityId,
      ...(currentTemplate && {
        ...currentTemplate,
        ownerId: currentTemplate.owner.id,
        voucherTypeId: currentTemplate.voucherType?.id,
        voucherUsageTypeId: currentTemplate.voucherUsageType?.id,
        printoutThemeId: currentTemplate.printoutTheme?.id,
        periodTypeId: currentTemplate.periodType?.id,
      }),
    }),
    [currentBusinessEntityId, currentTemplate]
  );

  const { form, handleSubmit, dirty } = useForm<VoucherTemplatePayload>({
    onSubmit,
    initialValues,
    subscription: { dirty: true },
  });

  const ignoreFormRestart = useRef(false);
  useEffect(() => {
    if (id) dispatch(getVoucherTemplate(id));
    return () => {
      if (!ignoreFormRestart.current) {
        dispatch(setVoucherTemplate());
        form.restart({ ownerId: currentBusinessEntityId });
      }
      ignoreFormRestart.current = false;
    };
  }, [currentBusinessEntityId, dispatch, form, id]);

  const handleFormReset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleDelete = useCallback(async () => {
    if (id) {
      await dispatch(deleteVoucherTemplate(id)).unwrap();
      alert.success(<TransAlert i18nKey="voucherTemplateDelete" />);
      history.replace('/vouchers-template');
      await dispatch(getVoucherTemplates());
    }
  }, [alert, dispatch, history, id]);

  const handleCopy = useCallback(() => {
    ignoreFormRestart.current = true;
    form.restart(
      _omit(form.getState().values, [
        'id',
        'name',
        'code',
        'priority',
        'unitPrices',
        'infos',
        'termsAndConditions',
      ])
    );
    history.replace('/vouchers-template/create');
  }, [history, form]);

  const closeDrawer = useCallback(() => {
    history.replace('/vouchers-template');
    dispatch(setVoucherTemplate());
  }, [dispatch, history]);

  return (
    <DrawerForm onClose={closeDrawer} open>
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                {id ? (
                  currentTemplate?.name
                ) : (
                  <TransSubtitle i18nKey="newVoucherTemplate" />
                )}
              </Typography>
            }
            action={
              <Stack direction="row">
                {id && (
                  <>
                    <Button
                      startIcon={<Icon name="duplicate" size={16} />}
                      size="small"
                      onClick={handleCopy}
                    >
                      <TransButton i18nKey="copy" />
                    </Button>
                    <Button
                      startIcon={<Icon name="trash" />}
                      onClick={handleDelete}
                      disabled={isRelatedToVouchers()}
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                  </>
                )}
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              </Stack>
            }
          />
          <CardContent
            sx={{ p: 3, pt: 2 }}
            component="form"
            onSubmit={handleSubmit}
          >
            <VouchersTemplateForm>
              <Grid container>
                <Grid item xs="auto" sx={{ ml: 'auto', mt: 2 }}>
                  <Stack direction="row" spacing={2}>
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      {...(!isEdit && {
                        component: Link,
                        to: '/vouchers-template',
                      })}
                      {...(isEdit && {
                        onClick: handleFormReset,
                        disabled: !dirty,
                      })}
                    >
                      <TransButton
                        i18nKey={isEdit ? 'resetChanges' : 'cancel'}
                      />
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      {...(!isEdit && { icon: 'plus24' })}
                      disabled={!dirty}
                    >
                      <TransButton i18nKey={isEdit ? 'save' : 'create'} />
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </VouchersTemplateForm>
          </CardContent>

          {id && (
            <Accordion>
              <AccordionPanel
                id="voucherInfo"
                summary={
                  <Typography variant="subtitle">
                    <TransSubtitle i18nKey="voucherInfo" />
                    &nbsp;
                    <Typography component="span" variant="body2">
                      ({currentTemplate?.infos?.length})
                    </Typography>
                  </Typography>
                }
              >
                <TermsAndInfoTable
                  type={TABLE_TYPE.INFO}
                  data={currentTemplate?.infos ?? []}
                />
              </AccordionPanel>

              <AccordionPanel
                id="unitPrices"
                hidden={
                  currentTemplate &&
                  currentTemplate?.voucherType.id === VOUCHER_TYPE.COMPENSATION
                }
                summary={
                  <Typography variant="subtitle">
                    <TransSubtitle i18nKey="unitPrices" />
                    &nbsp;
                    <Typography component="span" variant="body2">
                      ({currentTemplate?.unitPrices?.length})
                    </Typography>
                  </Typography>
                }
              >
                <UnitPriceTable data={currentTemplate?.unitPrices ?? []} />
              </AccordionPanel>

              <AccordionPanel
                id="termsAndConditions"
                summary={
                  <Typography variant="subtitle">
                    <TransSubtitle i18nKey="termsAndConditions" />
                    &nbsp;
                    <Typography component="span" variant="body2">
                      ({currentTemplate?.termsAndConditions?.length})
                    </Typography>
                  </Typography>
                }
              >
                <TermsAndInfoTable
                  type={TABLE_TYPE.TERMSANDCONDITIONS}
                  data={currentTemplate?.termsAndConditions ?? []}
                />
              </AccordionPanel>
            </Accordion>
          )}
        </FormProvider>
      </Loadable>
    </DrawerForm>
  );
};
