import flow from 'lodash/fp/flow';
import lowerCase from 'lodash/fp/lowerCase';
import toString from 'lodash/fp/toString';
import split from 'lodash/fp/split';
import some from 'lodash/fp/some';
import includes from 'lodash/fp/includes';

type String = string | null | undefined;
const clean = (str?: String) =>
  typeof str === 'string'
    ? // eslint-disable-next-line no-useless-escape
      str.replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>{}\[\]\\\/]/gi, '')
    : '';

const prepareString = flow(toString, lowerCase, clean);

const includesSpace = includes(' ');

type Filter = (string: String) => boolean;

export const filterByKeyword = (keyword: string): Filter => {
  const preparedInput = prepareString(keyword);
  const includesPreparedInput = includes(preparedInput);
  const someStartWithPreparedInput = flow(
    split(' '),
    some(includes(preparedInput))
  );

  const hasEntryPreparedInput = includesSpace(preparedInput)
    ? includesPreparedInput
    : someStartWithPreparedInput;

  return flow(prepareString, hasEntryPreparedInput);
};
