import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useRowSelect } from 'react-table';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { Box, Button, Stack } from '@mui/material';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup, Localization } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import { zoneSelector } from 'features/zoneMap/zoneMapSelectors';
import { makeStyles } from '@mui/styles';
import {
  createStopLocalization,
  getZone,
  removeStopLocalizations,
  updateStopLocalization,
} from 'features/zoneMap/zoneMapActions';
import _isEmpty from 'lodash/isEmpty';

interface ZoneLocalizationsProps {
  zoneMapId: string;
}

const useStyles = makeStyles(
  () => ({
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
  }),
  {
    name: 'StopTable',
  }
);

export const ZoneLocalizations: FC<ZoneLocalizationsProps> = ({
  zoneMapId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentZone = useSelector(zoneSelector);
  const cultures = useClassificationMap(ClassificationGroup.CULTURE);
  const cultureOptions = useClassificationOptions(ClassificationGroup.CULTURE);
  const localizations = useMemo(
    () => currentZone?.localizations ?? [],
    [currentZone?.localizations]
  );
  const usedLocalizations = useMemo(
    () => localizations.map(({ languageId }) => languageId),
    [localizations]
  );
  const availableCultureOptions = useMemo(
    () =>
      cultureOptions.filter(({ value }) => !usedLocalizations.includes(value)),
    [cultureOptions, usedLocalizations]
  );

  const { form } = useForm<{ rows: Array<Localization> }>({
    initialValues: {
      rows: localizations,
    },
  });

  const columns = useMemo<TableColumns<Localization>>(
    () => [
      {
        accessor: 'languageId',
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: cultures.get(value) },
            ...availableCultureOptions,
          ].filter(Boolean),
        }),
        Header: <TransTableHead i18nKey="language" />,
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
    ],
    [availableCultureOptions, cultures]
  );

  const handleRowUpdate = useCallback(
    async (payload) => {
      await dispatch(
        (payload.id ? updateStopLocalization : createStopLocalization)(payload)
      ).unwrap();
      await dispatch(getZone({ zoneMapId }));
    },
    [dispatch, zoneMapId]
  );

  const formTable = useFormTable<Localization>(
    {
      data: currentZone?.localizations ?? [],
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useIndeterminateRowSelectCheckbox,
    useRowSelect,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<Localization>) => {
      await dispatch(removeStopLocalizations(payload));
      await dispatch(getZone({ zoneMapId }));
    },
    [dispatch, zoneMapId]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table: formTable,
    form,
    removeQuery: onRowsRemove,
  });

  const handleRemoveSelectedRows = useCallback(async () => {
    await removeSelectedRows();
  }, [removeSelectedRows]);

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
          <Button
            variant="text"
            onClick={handleRemoveSelectedRows}
            disabled={_isEmpty(formTable.state.selectedRowIds)}
            startIcon={<Icon name="trash" />}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          classes={{
            cell: classes.tableCell,
          }}
          table={formTable}
        />
      </Box>
    </FormProvider>
  );
};
