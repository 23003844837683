import type { FC } from 'react';
import { useMemo } from 'react';
import { Tooltip } from '@fleet/shared/mui';
import { TransHint } from 'i18n/trans/hint';
import { SelectField } from '@fleet/shared/form';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { TransField } from 'i18n/trans/field';
import { Grid, Stack } from '@mui/material';
import { FormProvider, Icon, useForm } from '@fleet/shared';
import {
  FareModelStopsSelect,
  FareModelStopsSelectProps,
  FareModelStopsSelectValue,
  mapStops,
} from 'routes/FareModels/FareModelStopsSelect/FareModelStopsSelect';
import { useSelector } from 'store/utils';
import { FareModelStop } from 'dto/fareModel';
import { useTranslation } from 'react-i18next';

export interface FareModelStopsDetailsFormProps {
  originStops: Array<FareModelStop>;
  destinationStops: Array<FareModelStop>;
  onChangeStops: Required<FareModelStopsSelectProps>['onConfirm'];
}

interface FormValues {
  areFaresBidirectional: string;
  origin: FareModelStopsSelectValue;
  destination: FareModelStopsSelectValue;
}

export const FareModelStopsDetailsForm: FC<FareModelStopsDetailsFormProps> = ({
  originStops,
  destinationStops,
  onChangeStops,
}) => {
  const { areFaresBidirectional } = useSelector(fareModelSelector)!;
  const initialValues = useMemo<FormValues>(
    () => ({
      areFaresBidirectional: `${areFaresBidirectional}`,
      origin: mapStops(originStops),
      destination: mapStops(destinationStops),
    }),
    [areFaresBidirectional, destinationStops, originStops]
  );

  const { form } = useForm<FormValues>({ initialValues });
  const { t } = useTranslation();

  return (
    <FormProvider {...form}>
      <Grid container columns={4} spacing={2} rowSpacing={1} flexWrap="nowrap">
        <Grid item xs={1}>
          <SelectField
            name="areFaresBidirectional"
            label={
              <Stack direction="row" alignItems="center">
                <TransField i18nKey="fareDirection" />
                <Tooltip
                  content={<TransHint i18nKey="fareDirection" />}
                  delay={500}
                >
                  <Icon name="question" size={16} margin />
                </Tooltip>
              </Stack>
            }
            options={[
              {
                value: `true`,
                label: t(
                  'field.fareDirection.option.bidirectional',
                  'Bidirectional'
                ),
              },
              {
                value: `false`,
                label: t(
                  'field.fareDirection.option.unidirectional',
                  'Unidirectional'
                ),
              },
            ]}
            disabled
          />
        </Grid>
        <Grid item xs={1}>
          <FareModelStopsSelect
            name="origin"
            label={<TransField i18nKey="originStops" />}
            onConfirm={onChangeStops}
            allowSearch
          />
        </Grid>
        <Grid item xs={1}>
          <FareModelStopsSelect
            name="destination"
            label={
              <Stack direction="row" alignItems="center">
                <TransField i18nKey="destinationStops" />
                <Tooltip
                  content={<TransHint i18nKey="destinationStops" />}
                  delay={500}
                >
                  <Icon name="question" size={16} margin />
                </Tooltip>
              </Stack>
            }
            onConfirm={onChangeStops}
            allowSearch
          />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
