import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { Button, CardHeader, Divider, Stack, Typography } from '@mui/material';
import {
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { useFilters, useRowSelect, useTable } from 'react-table';
import { Checkbox, Icon } from '@fleet/shared/mui';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useDispatch, useSelector } from 'store/utils';
import { zoneMapListSelector } from 'features/zoneMap/zoneMapSelectors';
import { ZoneMap } from 'dto/zoneMap';
import { formatDate } from '@fleet/shared/utils/date';
import {
  deleteZoneMaps,
  getZoneMapList,
} from 'features/zoneMap/zoneMapActions';
import { Link } from 'react-router-dom';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ZoneMapSearchForm } from 'routes/ZoneMap/ZoneMapSearchForm';
import { useValidAccessor } from 'hooks/useValidAccessor';
import { zoneMapListLoading } from 'features/loading/loadingSelectors';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface ZoneMapTableProps {}

export const ZoneMapTable: FC<ZoneMapTableProps> = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const zoneMapList = useSelector(zoneMapListSelector);
  const businessEntities = useSelector(businessEntitiesSelector);
  const loading = useSelector(zoneMapListLoading);
  const isValidAccessor = useValidAccessor();

  const columns = useMemo<TableColumns<ZoneMap>>(
    () => [
      {
        id: 'name',
        accessor: ({ name, id }) => (
          <Link to={`/zone-fares/maps/edit/${id}`}>{name}</Link>
        ),
        Header: <TransTableHead i18nKey="mapName" />,
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        id: 'fareModel',
        accessor: ({ fareModel: { id, name } }) => (
          <Link to={`/fare-models/edit/${id}`}>{name}</Link>
        ),
        Header: <TransTableHead i18nKey="fareModel" />,
      },
      {
        id: 'owner',
        accessor: ({ owner }) =>
          businessEntities.find(({ id }) => id === owner?.id)?.name || '',
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        id: 'zones',
        accessor: ({ zones }) => zones.length,
        Header: <TransTableHead i18nKey="zones" />,
      },
      {
        id: 'validFrom',
        accessor: ({ validity }) => formatDate(validity.from),
        Header: <TransTableHead i18nKey="validFrom" />,
      },
      {
        id: 'validTo',
        accessor: ({ validity }) => formatDate(validity.to),
        Header: <TransTableHead i18nKey="validTo" />,
      },
      {
        id: 'isValid',
        accessor: isValidAccessor,
      },
    ],
    [isValidAccessor, businessEntities]
  );
  const getRowId = useCallback((row: ZoneMap) => `${row.id}`, []);

  const table = useTable(
    {
      data: zoneMapList,
      columns,
      initialState: {
        hiddenColumns: ['isValid'],
      },
      getRowId,
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );
  const selectedRowIds = useMemo(
    () => Object.keys(table.state.selectedRowIds).map((idx) => +idx),
    [table.state]
  );

  const removeSelectedRows = useCallback(async () => {
    await dispatch(deleteZoneMaps(selectedRowIds)).unwrap();
    dispatch(getZoneMapList());

    alert.success(<TransAlert i18nKey="zoneMapDeleted" />);
  }, [alert, dispatch, selectedRowIds]);

  const handleValidFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isValid', e.target.checked || undefined),
    [table]
  );

  return (
    <>
      <ZoneMapSearchForm />
      <Divider />
      <Loadable loading={loading}>
        <SearchResult results={zoneMapList.length} loading={loading}>
          <Table
            caption={
              <CardHeader
                subheader={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ gap: '20px' }}
                  >
                    <Typography
                      variant="subtitle"
                      fontWeight="700"
                      color="common.black"
                    >
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <div>
                      <Checkbox
                        onChange={handleValidFilterToggle}
                        label={
                          <Typography
                            variant="body2"
                            color="text.primary"
                            component="span"
                          >
                            <TransField i18nKey="showOnlyValidMaps" />
                          </Typography>
                        }
                        size="small"
                      />
                    </div>

                    <Typography
                      variant="body2"
                      component="span"
                      color="text.secondary"
                    >
                      <TransSubtitle
                        i18nKey="zoneMapsQty"
                        values={{ num: table.filteredRows?.length }}
                      />
                    </Typography>
                  </Stack>
                }
                action={
                  <Button
                    startIcon={<Icon name="trash" />}
                    sx={{ p: 0, whitespace: 'nowrap' }}
                    onClick={removeSelectedRows}
                    disabled={!Object.keys(table.state.selectedRowIds).length}
                    color="error"
                  >
                    <TransButton i18nKey="deleteSelected" />
                  </Button>
                }
              />
            }
            table={table}
          />
        </SearchResult>
      </Loadable>
    </>
  );
};
