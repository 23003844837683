import { FC, useCallback, useMemo } from 'react';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { Loadable, SearchResult, Table, TableColumns } from '@fleet/shared';
import { usePagination, useRowSelect, useTable } from 'react-table';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import { Link } from 'react-router-dom';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { makeStyles } from '@mui/styles';
import { VoucherBase } from 'dto/voucher';
import {
  selectVoucherFilter,
  vouchersListSelector,
} from 'features/voucher/vouchersSelector';
import { VouchersSearchForm } from 'routes/Vouchers/VouchersSearchForm';
import { TransTableHead } from 'i18n/trans/table';
import { getVouchers } from 'features/voucher/vouchersActions';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { vouchersLoadingSelector } from 'features/loading/loadingSelectors';

interface VouchersTableProps {}

const useStyles = makeStyles(
  () => ({
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
    thead: {
      zIndex: 1,
    },
  }),
  {
    name: 'UsersTable',
  }
);

export const VouchersTable: FC<VouchersTableProps> = () => {
  const classes = useStyles();
  const vouchersList = useSelector(vouchersListSelector);
  const dispatch = useDispatch();
  const loading = useSelector(vouchersLoadingSelector);
  const data = useMemo(() => vouchersList?.items ?? [], [vouchersList]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (vouchersList) {
        const { limit = pageSize, offset } = vouchersList;
        return offset / limit;
      }
      return 0;
    },
    [vouchersList]
  );

  const filter = useSelector(selectVoucherFilter);
  const handlePageChange = useCallback(
    async (paginationParams) => {
      await dispatch(getVouchers({ ...filter, ...paginationParams })).unwrap();
    },
    [dispatch, filter]
  );

  const columns = useMemo<TableColumns<VoucherBase>>(
    () => [
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="voucherCode" />,
        Cell: ({ value, row }) => (
          <Link to={`/vouchers/${row.original.id}`}>{value}</Link>
        ),
      },
      {
        accessor: 'owner',
        Header: <TransTableHead i18nKey="owner" />,
        Cell: ({ value }) => value.name,
      },
      {
        accessor: 'validUntil',
        Header: <TransTableHead i18nKey="validUntil" />,
        type: 'date',
        Cell: ({ value }) => formatDate(value.value, currentDateTimeFormat),
      },
      {
        accessor: 'nominalValue',
        Header: <TransTableHead i18nKey="nominalValue" />,
        Cell: ({ value, row }) =>
          `${value.toFixed(2)} ${row.original.currency.name}`,
      },
      {
        accessor: 'usedAmount',
        Header: <TransTableHead i18nKey="usedAmount" />,
        Cell: ({ value, row }) =>
          `${value.toFixed(2)} ${row.original.currency.name}`,
      },
      {
        accessor: 'numberOfPaymentsUsed',
        Header: <TransTableHead i18nKey="numberOfPaymentsUsed" />,
        Cell: ({ row, value }) =>
          `${value} / ${row.values.maxNumberOfPayments ?? '-'}`,
      },
      {
        accessor: 'numberOfProductsUsed',
        Header: <TransTableHead i18nKey="numberOfProductsUsed" />,
        Cell: ({ row, value }) =>
          `${value} / ${row.values.numberOfProductsUsed ?? '-'}`,
      },
      {
        accessor: 'createdBy',
        Header: <TransTableHead i18nKey="createdBy" />,
        Cell: ({ value }) => value.name,
      },
      {
        accessor: 'isCancelled',
        Header: <TransTableHead i18nKey="cancelledOn" />,
        Cell: ({ value }) => (value ? 'Yes' : '-'),
      },
      {
        accessor: 'voucherType',
        Header: <TransTableHead i18nKey="voucherType" />,
        Cell: ({ value }) => value.name,
      },
    ],
    []
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      total: vouchersList?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowSelect,
    useRowSelectCheckbox
  );

  return (
    <>
      <Loadable loading={loading}>
        <VouchersSearchForm />
        <Divider />
        <SearchResult results={data.length} loading={loading}>
          <Table
            classes={{
              cell: classes.tableCell,
              thead: classes.thead,
            }}
            caption={
              <CardContent>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 2 }}
                  >
                    <TransSubtitle
                      i18nKey="vouchersQty"
                      values={{ num: data.length }}
                    />
                  </Typography>
                </Stack>
              </CardContent>
            }
            table={table}
          />
        </SearchResult>
      </Loadable>
    </>
  );
};
