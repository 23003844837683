import { type FC } from 'react';
import { Loadable, Layout, AddButton, CardHeader } from '@fleet/shared';
import { PassengerTypeDiscountsTable } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountsTable';
import { PassengerTypeDiscountsForm } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountsForm';
import { useSelector } from 'store/utils';
import { passengerTypeDiscountsLoadingSelector } from 'features/loading/loadingSelectors';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';

interface PassengerTypeDiscountsProps {}

export const PassengerTypeDiscounts: FC<PassengerTypeDiscountsProps> = () => {
  const history = useHistory();
  const loading = useSelector(passengerTypeDiscountsLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="passengerTypeDiscounts" />}>
            <AddButton
              onClick={() => history.push('/passenger-type-discounts/create')}
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/passenger-type-discounts/:action(create|edit)/:id?"
              component={PassengerTypeDiscountsForm}
              exact
            />
          </Switch>
        }
      >
        <PassengerTypeDiscountsTable />
      </Layout>
    </Loadable>
  );
};
