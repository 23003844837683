import { FC, useMemo } from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import { AddButton, CardHeader, Layout, Loadable } from '@fleet/shared';
import { VouchersTemplateTable } from 'routes/VouchersTemplate/VouchersTemplateTable';
import { VouchersTemplateDetailsForm } from 'routes/VouchersTemplate/VouchersTemplateDetailsForm';
import { useSelector } from 'store/utils';
import { vouchersTemplatesLoadingSelector } from 'features/loading/loadingSelectors';
import { TransNav } from 'i18n/trans/nav';
import { TransButton } from 'i18n/trans/button';

interface VouchersTemplateProps {}

export const VouchersTemplate: FC<VouchersTemplateProps> = () => {
  const history = useHistory();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isCreation = useMemo(() => Boolean(action === 'create'), [action]);
  const isEdit = useMemo(() => Boolean(action === 'edit' && id), [action, id]);

  const loading = useSelector(vouchersTemplatesLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransNav i18nKey="vouchersTemplate" />}>
            <AddButton
              onClick={() => history.push('/vouchers-template/create')}
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Route
            path={`/vouchers-template/:action(create|edit)/:id?`}
            render={() => (
              <VouchersTemplateDetailsForm
                isCreation={isCreation}
                isEdit={isEdit}
              />
            )}
            exact
          />
        }
      >
        <VouchersTemplateTable />
      </Layout>
    </Loadable>
  );
};
