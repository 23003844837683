import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  copyFareModel: (
    <Trans i18nKey="modal.title.copyFareModel" defaults="Copy fare model" />
  ),
  deleteFareModel: (
    <Trans
      i18nKey="modal.title.deleteFareModel"
      defaults="Are you sure you want to delete the fare model"
    />
  ),
  deletePrice: (
    <Trans
      i18nKey="modal.title.deletePrice"
      defaults="Are you sure you want to delete the price"
    />
  ),
  distanceFareDeletionDescription: (
    <Trans
      i18nKey="modal.title.distanceFareDeletionDescription"
      defaults="Are you sure you want to delete selected distance fares? It cannot be undone."
    />
  ),
  distanceFareDeletionTitle: (
    <Trans
      i18nKey="modal.title.distanceFareDeletionTitle"
      defaults="Delete distance fares"
    />
  ),
  fareModelFareDeletionDescription: (
    <Trans
      i18nKey="modal.title.fareModelFareDeletionDescription"
      defaults="Are you sure you want to delete selected fare(s)? It cannot be undone."
    />
  ),
  fareModelFareDeletionTitle: (
    <Trans
      i18nKey="modal.title.fareModelFareDeletionTitle"
      defaults="Delete fares"
    />
  ),
  fixedFareDeletionDescription: (
    <Trans
      i18nKey="modal.title.fixedFareDeletionDescription"
      defaults="Are you sure you want to delete selected fixed fare(s)? It cannot be undone."
    />
  ),
  fixedFareDeletionTitle: (
    <Trans
      i18nKey="modal.title.fixedFareDeletionTitle"
      defaults="Delete fixed fare(s)"
    />
  ),
  passengerTypeTranslationDeletionDescription: (
    <Trans
      i18nKey="modal.title.passengerTypeTranslationDeletionDescription"
      defaults="Are you sure you want to delete selected passenger type translation? It cannot be undone."
    />
  ),
  passengerTypeTranslationDeletionTitle: (
    <Trans
      i18nKey="modal.title.passengerTypeTranslationDeletionTitle"
      defaults="Delete passenger type translation"
    />
  ),
  passengerTypeValidityDeletionDescription: (
    <Trans
      i18nKey="modal.title.passengerTypeValidityDeletionDescription"
      defaults="Are you sure you want to delete selected passenger type validity? It cannot be undone."
    />
  ),
  passengerTypeValidityDeletionTitle: (
    <Trans
      i18nKey="modal.title.passengerTypeValidityDeletionTitle"
      defaults="Delete passenger type validity"
    />
  ),
  selectStops: (
    <Trans i18nKey="modal.title.selectStops" defaults="Select stops" />
  ),
});
