import { TicketMinimalCostValue } from 'dto/common';
import { renderToString } from 'react-dom/server';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { ModalFieldArray, SelectField, TextField } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { cloneDeep, noop, unset } from 'lodash';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useCallback } from 'react';
import { PassengerTypeDiscountValues } from 'dto/passengerTypeDiscount';
import { TransValidate } from 'i18n/trans/validate';

export const TicketMinimalCostField = () => {
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );
  const currencyMap = useClassificationMap(ClassificationGroup.CURRENCY);

  const validateCurrency = useCallback(
    (value: string, allValues: PassengerTypeDiscountValues, meta) => {
      const copyValues = cloneDeep(allValues);
      unset(copyValues, meta.name); // delete current value from all
      const { ticketMinimalCost } = copyValues;
      if (!value) {
        return renderToString(<TransField i18nKey="required" />);
      }

      if (
        value &&
        ticketMinimalCost.map(({ currencyId }) => currencyId).includes(value)
      ) {
        return renderToString(
          <TransValidate
            i18nKey="uniq"
            values={{
              field: renderToString(<TransField i18nKey="currency" />),
            }}
          />
        );
      }
      return undefined;
    },
    []
  );

  const validateAmount = useCallback((value: string) => {
    if (Number(value) < 0) {
      return renderToString(<TransField i18nKey="required" />);
    }
  }, []);

  const validateRow = useCallback((rows?: Array<TicketMinimalCostValue>) => {
    if (rows?.some((row) => Object.keys(row).length === 0) || !rows?.length) {
      return renderToString(
        <TransField i18nKey="minimalCostFieldsCantBeEmpty" />
      );
    }
    return null;
  }, []);

  return (
    <ModalFieldArray<TicketMinimalCostValue>
      required
      name="ticketMinimalCost"
      label={<TransField i18nKey="ticketMinimalCost" />}
      accessor={({ amount, currencyId }) => {
        return amount !== undefined && currencyId !== undefined
          ? [amount, currencyMap.get(currencyId)].join(' ')
          : '';
      }}
      validate={validateRow}
      render={({ name, removeButton }) => ({
        canRemove: false,
        field: (
          <Grid container spacing={2}>
            <Grid item sx={{ flex: 1 }}>
              <TextField
                validate={validateAmount}
                name={`${name}.amount`}
                type="number"
              />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <SelectField
                name={`${name}.currencyId`}
                options={currencyOptions}
                validate={validateCurrency}
              />
            </Grid>
            <Grid item>{removeButton}</Grid>
          </Grid>
        ),
      })}
      modalProps={{
        actionButton: {
          label: <TransButton i18nKey="confirm" />,
          icon: 'check',
          onClick: noop,
        },
      }}
    />
  );
};
