import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { fareModelStopsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  fareModelDestinationStopsSelector,
  fareModelOriginStopsSelector,
  fareModelSelector,
} from 'features/fareModel/fareModelSelectors';
import { CardHeader, Layout, Loadable, useLoaded } from '@fleet/shared';
import { Button, Icon } from '@fleet/shared/mui';
import {
  getFareModel,
  getFareModelOriginDestinationStops,
  setFareModel,
  updateFareModelStops,
} from 'features/fareModel/fareModelActions';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  FareModelStopsDetailsForm,
  FareModelStopsDetailsFormProps,
} from 'routes/FareModels/FareModelStops/FareModelStopsDetailsForm';
import { useDispatch, useSelector } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { FareModelStopsContent } from 'routes/FareModels/FareModelStops/FareModelStopsContent';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      overflowX: 'hidden',
      zIndex: 1,
      flex: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    layout: {
      flex: '0 !important',
      minHeight: 'unset !important',
    },
    layoutCol: {
      padding: `16px 24px`,
    },
  }),
  {
    name: 'FareModelStops',
  }
);

interface FareModelStopsProps {}

export const FareModelStops: FC<FareModelStopsProps> = () => {
  const { id } = useParams<{ id: string }>();
  const loading = useSelector(fareModelStopsLoadingSelector);
  const loaded = useLoaded(loading);
  const classes = useStyles();
  const fareModel = useSelector(fareModelSelector);
  const originStops = useSelector(fareModelOriginStopsSelector);
  const destinationStops = useSelector(fareModelDestinationStopsSelector);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFareModel());
    (async () => {
      await dispatch(getFareModel(id));
      await dispatch(getFareModelOriginDestinationStops(id));
    })();
  }, [dispatch, id]);
  const handleChangeStops = useCallback<
    FareModelStopsDetailsFormProps['onChangeStops']
  >(
    async (name, stops) => {
      await dispatch(
        updateFareModelStops({
          type: name as 'origin' | 'destination',
          stopIds: Array.from(stops.keys()),
        })
      );
      await dispatch(getFareModelOriginDestinationStops(id));
    },
    [dispatch, id]
  );

  return (
    <Loadable loading={loading} portal>
      {loaded && fareModel && (
        <div className={classes.root}>
          <Layout
            classes={{ root: classes.layout, col: classes.layoutCol }}
            header={
              <CardHeader
                title={
                  <TransTitle
                    i18nKey="fareModelOriginDestinationFares"
                    values={fareModel}
                  />
                }
              >
                <Button
                  variant="text"
                  startIcon={<Icon name="arrow-left-circle" />}
                  onClick={() =>
                    history.push(`/fare-models/edit/${fareModel?.id}`)
                  }
                  textColorString="text.warning"
                >
                  <TransButton i18nKey="back" />
                </Button>
              </CardHeader>
            }
          >
            <FareModelStopsDetailsForm
              originStops={originStops}
              destinationStops={destinationStops}
              onChangeStops={handleChangeStops}
            />
          </Layout>
          <FareModelStopsContent />
        </div>
      )}
    </Loadable>
  );
};
