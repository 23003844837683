import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { fareModelFixedLoadingSelector } from 'features/loading/loadingSelectors';
import { usePagination, useRowSelect } from 'react-table';
import {
  ConfirmDeleteModal,
  FormProvider,
  SearchResult,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { Button, CardContent, Divider, Stack, Typography } from '@mui/material';
import { TransTableHead } from 'i18n/trans/table';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FareModelFixedSearchForm } from 'routes/FareModels/FareModelFixed/FareModelFixedSearchForm';
import {
  fareModelFixedFilterSelector,
  fareModelFixedListSelector,
} from 'features/fareModel/fareModelFixedSelectors';
import { FareModelFixedFare } from 'dto/fareModelFixed';
import {
  createOrUpdateFixedFare,
  deleteFixedFares,
  getFixedFares,
} from 'features/fareModel/fareModelFixedActions';
import { validate } from '@fleet/shared/form/validate';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransModal } from 'i18n/trans/modal';

export const FareModelFixedTable: FC = () => {
  const fixedFares = useSelector(fareModelFixedListSelector);
  const data = useMemo(() => fixedFares?.items ?? [], [fixedFares]);
  const dispatch = useDispatch();
  const alert = useAlert();
  const loading = useSelector(fareModelFixedLoadingSelector);
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );
  const fareCategoryOptions = useClassificationOptions(
    ClassificationGroup.FARE_CATEGORY
  );
  const filter = useSelector(fareModelFixedFilterSelector);
  const { open: isOpen, onOpen, onClose } = useModal();

  const columns = useMemo<TableColumns<FareModelFixedFare>>(
    () => [
      {
        accessor: 'fare',
        Header: <TransTableHead i18nKey="fare" />,
        editableProps: {
          validate: validate.required(),
          type: 'number',
        },
      },
      {
        id: 'currency.id',
        accessor: ({ currency }) => currency?.id,
        Header: <TransTableHead i18nKey="currency" />,
        type: 'select',
        editableProps: {
          validate: validate.required(),
          options: currencyOptions,
        },
      },
      {
        id: 'fareCategory.id',
        accessor: ({ fareCategory }) => fareCategory?.id,
        Header: <TransTableHead i18nKey="fareCategory" />,
        type: 'select',
        editableProps: {
          validate: validate.required(),
          options: fareCategoryOptions,
        },
      },
    ],
    [currencyOptions, fareCategoryOptions]
  );

  const { form } = useForm<{ rows: Array<FareModelFixedFare> }>({
    initialValues: { rows: data },
  });

  const onRowUpdate = useCallback(
    async ({ fareCategory, currency, ...rest }: FareModelFixedFare) => {
      await dispatch(
        createOrUpdateFixedFare({
          ...rest,
          fareCategoryId: fareCategory?.id,
          currencyId: currency?.id,
        })
      ).unwrap();

      if (rest.id) {
        alert.success(<TransAlert i18nKey="fareModelFixedFareUpdated" />);
      } else {
        alert.success(<TransAlert i18nKey="fareModelFixedFareUpdated" />);
      }

      await dispatch(getFixedFares()).unwrap();
    },
    [alert, dispatch]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (fixedFares) {
        const { limit = pageSize, offset } = fixedFares;
        return offset / limit;
      }
      return 0;
    },
    [fixedFares]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getFixedFares({ ...filter, ...paginationParams })
      ).unwrap(),
    [dispatch, filter]
  );

  const table = useFormTable(
    {
      data,
      columns,
      form,
      onRowUpdate,
      pageCount: -1,
      total: fixedFares?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (rows: Array<FareModelFixedFare>) => {
      const ids = rows.map((row) => row.id);

      await dispatch(deleteFixedFares(ids)).unwrap();
      alert.success(<TransAlert i18nKey="fareModelFixedFareUpdated" />);

      onClose();
      await dispatch(getFixedFares()).unwrap();
    },
    [alert, dispatch, onClose]
  );

  const { addRow, removeSelectedRows } =
    useFormTableControls<FareModelFixedFare>({
      table,
      form,
      removeQuery: onRowsRemove,
    });

  return (
    <>
      <FareModelFixedSearchForm />
      <Divider />
      <SearchResult results loading={loading} i18nKey="noDataAdded">
        <FormProvider {...form}>
          <Table
            caption={
              <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    <Typography variant="subtitle" fontWeight="700">
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>

                    {Boolean(fixedFares?.totalCount) && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 2 }}
                      >
                        <TransSubtitle
                          i18nKey="fareModelFixedFaresQty"
                          values={{ count: fixedFares!.totalCount }}
                          tOptions={{ postProcess: 'interval' }}
                        />
                      </Typography>
                    )}
                  </Stack>

                  <Stack direction="row" alignItems="center">
                    <Button
                      variant="text"
                      onClick={onOpen}
                      startIcon={<Icon name="delete" />}
                      disabled={!Object.keys(table.state.selectedRowIds).length}
                      color="error"
                    >
                      <TransButton i18nKey="deleteSelected" />
                    </Button>
                    <ConfirmDeleteModal
                      handleDelete={removeSelectedRows}
                      title={<TransModal i18nKey="fixedFareDeletionTitle" />}
                      description={
                        <TransModal i18nKey="fixedFareDeletionDescription" />
                      }
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                    <Button
                      variant="text"
                      onClick={addRow}
                      startIcon={<Icon name="plus" />}
                    >
                      <TransButton i18nKey="addNew" />
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            }
            table={table}
          />
        </FormProvider>
      </SearchResult>
    </>
  );
};
