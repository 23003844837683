import { useCallback } from 'react';
import {
  Button,
  FormControl,
  FormProvider,
  SearchForm,
  TextField,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Grid, Stack } from '@mui/material';
import { SelectField } from '@fleet/shared/form';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import { SelectCurrencyField } from 'components/SelectCurrencyField';
import { getFareModelDistanceFares } from 'features/fareModel/fareModelDistanceActions';
import { FareModelDistanceSearchParams } from 'dto/fareModelDistance';
import { fareModelDistanceFaresFilterSelector } from 'features/fareModel/fareModelDistanceSelectors';

export const FareModelDistanceRangesFilterForm = () => {
  const dispatch = useDispatch();
  const fareCategoryOptions = useClassificationOptions(
    ClassificationGroup.FARE_CATEGORY
  );
  const filter = useSelector(fareModelDistanceFaresFilterSelector);

  const onSubmit = useCallback(
    (values: FareModelDistanceSearchParams) =>
      formSubmit(async () => {
        dispatch(getFareModelDistanceFares({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const { form, handleSubmit } = useForm<FareModelDistanceSearchParams>({
    initialValues: filter,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="filterFares" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={1} spacing={2}>
            <Grid item xs={1}>
              <Grid container columns={3} spacing={2}>
                <Grid item xs={1}>
                  <SelectCurrencyField showEmptyOption />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="fareCategoryId"
                    options={fareCategoryOptions}
                    label={<TransField i18nKey="category" />}
                    showEmptyOption
                  />
                </Grid>
                <Grid item xs="auto" sx={{ ml: 'auto' }}>
                  <Stack direction="row" spacing={2}>
                    <FormControl label="&nbsp;">
                      <Button
                        sx={{ whiteSpace: 'nowrap' }}
                        variant="text"
                        onClick={handleReset}
                      >
                        <TransButton i18nKey="resetFilters" />
                      </Button>
                    </FormControl>
                    <FormControl label="&nbsp;">
                      <Button variant="contained" type="submit" icon="filter">
                        <TransButton i18nKey="filter" />
                      </Button>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Grid container columns={3} spacing={2}>
                <Grid item xs={1}>
                  <FormControl label={<TransField i18nKey="distanceFrom" />}>
                    <Grid container columns={3} spacing={2} alignItems="center">
                      <Grid item xs>
                        <TextField name="distanceFromStart" type="number" />
                      </Grid>
                      <Grid item xs="auto">
                        -
                      </Grid>
                      <Grid item xs>
                        <TextField name="distanceFromEnd" type="number" />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl label={<TransField i18nKey="distanceTo" />}>
                    <Grid container columns={3} spacing={2} alignItems="center">
                      <Grid item xs>
                        <TextField name="distanceToStart" type="number" />
                      </Grid>
                      <Grid item xs="auto">
                        -
                      </Grid>
                      <Grid item xs>
                        <TextField name="distanceToEnd" type="number" />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
