import { FC, useCallback } from 'react';
import { Grid, Link as MuiLink } from '@mui/material';
import { DateField, CheckboxField } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { ReadOnlyField } from '@fleet/shared/mui/ReadOnlyField';
import { Voucher } from 'dto/voucher';
import { Link } from 'react-router-dom';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';

interface VoucherFormProps {
  data?: Voucher;
}

export const VoucherForm: FC<VoucherFormProps> = ({ data, children }) => {
  const calcRemainingValue = useCallback(() => {
    if (data) {
      const nominalValue = data?.nominalValue;
      const activitiesAmount = data?.activities.reduce(
        (accum, nextActivities) => accum + nextActivities.amount,
        0
      );

      return `${(nominalValue - activitiesAmount).toFixed(2)}`;
    }
  }, [data]);

  return (
    <Grid container columns={5} spacing={2}>
      <Grid item xs={1} sx={{ mb: 1 }}>
        <ReadOnlyField
          value={data?.owner?.name}
          label={<TransField i18nKey="owner" />}
        />
      </Grid>
      <Grid item xs={1} sx={{ mb: 1 }}>
        <ReadOnlyField
          value={data?.createdBy?.name}
          label={<TransField i18nKey="createdBy" />}
        />
      </Grid>
      <Grid item xs={1} sx={{ mb: 1 }}>
        <ReadOnlyField
          value={
            data
              ? formatDate(data?.createdOn?.value, currentDateTimeFormat)
              : ''
          }
          label={<TransField i18nKey="createdOn" />}
        />
      </Grid>
      <Grid item xs={1}>
        <DateField
          name="validUntil.value"
          label={<TransField i18nKey="validUntil" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.voucherType?.name}
          label={<TransField i18nKey="voucherType" />}
        />
      </Grid>
      {data?.voucherType.name === 'Loyalty' && (
        <Grid item xs={1}>
          <ReadOnlyField
            value={data?.voucherUsageType?.name}
            label={<TransField i18nKey="voucherUsageType" />}
          />
        </Grid>
      )}
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.code}
          label={<TransField i18nKey="code" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.booking?.number}
          label={<TransField i18nKey="bookingNumber" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.transaction?.number}
          label={<TransField i18nKey="ticketNumber" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.travelAccount?.email}
          label={<TransField i18nKey="travelAccount" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={
            <MuiLink
              component={Link}
              to={`/vouchers-template/edit/${data?.voucherTemplate?.id}`}
              underline="none"
            >
              {data?.voucherTemplate?.name}
            </MuiLink>
          }
          label={<TransField i18nKey="voucherTemplate" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={`${data?.numberOfPaymentsUsed} / ${
            data?.maxNumberOfPayments || '-'
          }`}
          label={<TransField i18nKey="usedPayments" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={`${data?.numberOfProductsUsed} / ${
            data?.maxNumberOfProducts || '-'
          }`}
          label={<TransField i18nKey="usedProducts" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.nominalValue.toFixed(2)}
          label={
            <TransField
              i18nKey="nominalValue"
              values={{ currency: data?.currency.name }}
            />
          }
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={calcRemainingValue()}
          label={<TransField i18nKey="remainingValue" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.usedAmount.toFixed(2)}
          label={
            <TransField
              i18nKey="usedAmount"
              values={{ currency: data?.currency.name }}
            />
          }
        />
      </Grid>
      <Grid item xs={1}>
        <CheckboxField
          labelPosition="top"
          name="isCancelled"
          label={<TransField i18nKey="isCancelled" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.cancelledOn}
          label={<TransField i18nKey="cancelledOn" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={data?.cancelledBy}
          label={<TransField i18nKey="cancelledBy" />}
        />
      </Grid>
      {children}
    </Grid>
  );
};
