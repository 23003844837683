import { FC, useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  AccordionPanel,
  FormControl,
  Tooltip,
} from '@fleet/shared/mui';
import {
  CardHeader,
  CheckboxField,
  DrawerForm,
  FormProvider,
  Icon,
  Loadable,
  TimeZoneField,
  useForm,
} from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CoTaxationGroupCarriers } from 'routes/CoTaxationGroups/CoTaxationGroupCarriers';
import {
  setCoTaxationGroup,
  createCoTaxationGroup,
  getCoTaxationGroup,
  getCoTaxationGroups,
  updateCoTaxationGroup,
} from 'features/coTaxationGroup/coTaxationGroupActions';
import { CoTaxationGroup } from 'dto/coTaxationGroup';
import { useDispatch, useSelector } from 'store/utils';
import { currentCoTaxationGroupSelector } from 'features/coTaxationGroup/coTaxationGroupSelectors';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { coTaxationGroupLoading } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'StopForm',
  }
);

export interface CoTaxationGroupFormProps {}

export const CoTaxationGroupForm: FC<CoTaxationGroupFormProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCoTaxationGroup = useSelector(currentCoTaxationGroupSelector);
  const currentCoTaxationGroupId = currentCoTaxationGroup?.id;
  const loading = useSelector(coTaxationGroupLoading);

  useEffect(() => {
    dispatch(setCoTaxationGroup());
    if (action === 'edit' && id) {
      dispatch(getCoTaxationGroup(id));
    }
    return () => {
      dispatch(setCoTaxationGroup());
    };
  }, [action, dispatch, id]);

  const onSubmit = useCallback(
    async (values: CoTaxationGroup) => {
      const enhancedValues = { ...values, isActive: Boolean(values.isActive) };
      const data = await dispatch(
        (currentCoTaxationGroupId
          ? updateCoTaxationGroup
          : createCoTaxationGroup)(enhancedValues)
      ).unwrap();

      if (data?.id) {
        history.replace(`/co-taxation-groups/edit/${data.id}`);
      }

      dispatch(getCoTaxationGroups());
      alert.success(
        <TransAlert
          i18nKey="coTaxationCreated"
          {...(currentCoTaxationGroupId && { i18nKey: 'coTaxationUpdated' })}
        />
      );
    },
    [dispatch, currentCoTaxationGroupId, alert, history]
  );

  const { form, handleSubmit } = useForm<CoTaxationGroup>({ onSubmit });
  const { reset } = form;

  useEffect(() => {
    reset(currentCoTaxationGroupId ? currentCoTaxationGroup : {});
  }, [currentCoTaxationGroup, currentCoTaxationGroupId, reset]);

  const handleFormReset = useCallback(
    () => reset(currentCoTaxationGroup),
    [reset, currentCoTaxationGroup]
  );

  const handleDelete = useCallback(() => {
    // TODO await dispatch() missing delete endpoint
    alert.success(<TransAlert i18nKey="coTaxationDeleted" />);
    history.replace('/co-taxation-groups/list');
    dispatch(getCoTaxationGroups());
  }, [alert, history, dispatch]);

  const closeDrawer = useCallback(() => {
    history.replace('/co-taxation-groups');
    dispatch(setCoTaxationGroup());
  }, [dispatch, history]);

  const classes = useStyles();
  return (
    <DrawerForm open onClose={closeDrawer}>
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                {(id && currentCoTaxationGroup?.name) ?? (
                  <TransSubtitle i18nKey="newCoTaxationGroup" />
                )}
              </Typography>
            }
            action={
              <>
                {currentCoTaxationGroupId && (
                  <Button
                    startIcon={<Icon name="delete" />}
                    onClick={handleDelete}
                    disabled // delete endpoint missing
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                )}
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              </>
            }
          />
          <CardContent
            sx={{ p: 3, pt: 2 }}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs>
                <TextField
                  name="name"
                  label={<TransField i18nKey="name" />}
                  required
                />
              </Grid>
              <Grid item xs>
                <TextField
                  name="code"
                  label={<TransField i18nKey="code" />}
                  required
                />
              </Grid>
              <Grid item xs>
                <TimeZoneField
                  name="operatingTimezone"
                  label={<TransField i18nKey="operatingTimezone" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <CheckboxField
                  labelPosition="top"
                  name="isActive"
                  label={<TransField i18nKey="isActive" />}
                />
              </Grid>
            </Grid>
            <FormControl label="&nbsp;" labelPosition="top" sx={{ ml: 'auto' }}>
              <Stack direction="row" justifyContent="end" spacing={2}>
                {id ? (
                  <Button onClick={handleFormReset}>
                    <TransButton i18nKey="resetChanges" />
                  </Button>
                ) : (
                  <Button component={Link} to="/co-taxation-groups">
                    <TransButton i18nKey="cancel" />
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<Icon name={id ? 'check' : 'plus'} />}
                >
                  <TransButton i18nKey={id ? 'save' : 'create'} />
                </Button>
              </Stack>
            </FormControl>
          </CardContent>

          {currentCoTaxationGroupId && (
            <Accordion>
              <AccordionPanel
                id="carriers"
                summary={
                  <Typography variant="subtitle">
                    <TransSubtitle i18nKey="carriers" />
                    &nbsp;
                    <Typography component="span" variant="body2">
                      ({currentCoTaxationGroup?.contacts?.length})
                    </Typography>
                  </Typography>
                }
              >
                <CoTaxationGroupCarriers />
              </AccordionPanel>
            </Accordion>
          )}
        </FormProvider>
      </Loadable>
    </DrawerForm>
  );
};
