import { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  RadioGroupField,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { VoucherFilter } from 'dto/voucher';
import {
  getVouchers,
  setVoucherFilter,
} from 'features/voucher/vouchersActions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { SelectOwnerField } from 'components/SelectOwnerField';
import { SelectCurrencyField } from 'components/SelectCurrencyField';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { voucherFilterSelector } from 'features/voucher/vouchersSelector';

const useStyles = makeStyles(
  () => ({
    nominalPrice: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
    },
  }),
  {
    name: 'VouchersSearchForm',
  }
);

export const VouchersSearchForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(voucherFilterSelector);
  const voucherTypes = useClassificationOptions(
    ClassificationGroup.VOUCHER_TYPE
  );

  const onSubmit = useCallback(
    (values: Partial<VoucherFilter>) => {
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        dispatch(getVouchers({ ...values, offset: 0 }));
      });
    },
    [dispatch]
  );

  const initialValues = useMemo(
    () => ({
      ownerId: currentBusinessEntityId,
      validFrom: formatDate(new Date(), isoDateTimeFormat),
      ...filter,
    }),
    [filter, currentBusinessEntityId]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setVoucherFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm<VoucherFilter>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setVoucherFilter({}));
  }, [dispatch, form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <SelectOwnerField />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="code"
                label={<TransField i18nKey="voucherCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="createdBy"
                label={<TransField i18nKey="createdBy" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                options={voucherTypes}
                name="typeId"
                label={<TransField i18nKey="voucherType" />}
              />
            </Grid>
            <Grid className={classes.nominalPrice} item xs={1}>
              <FormControl label={<TransField i18nKey="nominalPrice" />}>
                <Stack direction="row" alignItems="center">
                  <TextField name="nominalValueFrom" type="number" />
                  <Typography variant="body2" sx={{ p: 1 }}>
                    <TransField i18nKey="to" />
                  </Typography>
                  <TextField name="nominalValueTo" type="number" />
                </Stack>
              </FormControl>
            </Grid>
            <PeriodField
              from={{
                name: 'validFrom',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'validTo',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
              }}
            />
            <Grid item xs={1}>
              <SelectCurrencyField />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                name="isCancelled"
                label={<TransField i18nKey="isCancelled" />}
                options="BOOL"
                inline
              />
            </Grid>
            <Stack
              direction="row"
              sx={{ ml: 'auto', mt: 4, alignItems: 'end' }}
              spacing={1}
            >
              <Button onClick={handleReset} variant="text">
                <TransButton i18nKey="reset" />
              </Button>
              <Button icon="search" type="submit" sx={{ ml: 2 }}>
                <TransButton i18nKey="search" />
              </Button>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
