import type { FC } from 'react';
import { Field, Icon, SelectField, useFormContext } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { Stack } from '@mui/material';
import { Tooltip } from '@fleet/shared/mui';
import { TransHint } from 'i18n/trans/hint';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { inventoryClassCompartmentIdSelector } from 'features/classification/classificationSelectors';
import { PromotionalDiscountValues } from 'dto/promotionalDiscount';

interface PromotionalDiscountDetailsFormInventoryClassesFieldProps {}

export const PromotionalDiscountDetailsFormInventoryClassesField: FC<PromotionalDiscountDetailsFormInventoryClassesFieldProps> =
  () => {
    const {
      initialValues: { inventoryClasses: initialInventoryClasses = [] },
    } = useFormContext<PromotionalDiscountValues>().getState();

    const inventoryClassOptions = useClassificationOptions(
      ClassificationGroup.INVENTORY_CLASS
    );
    const compartmentInventoryClassId = useSelector(
      inventoryClassCompartmentIdSelector
    );

    return (
      <SelectField
        options={inventoryClassOptions}
        name="inventoryClasses"
        label={
          <Field
            name="inventoryClasses"
            render={({ input: { value } }) => (
              <Stack direction="row" alignItems="center">
                <TransField i18nKey="inventoryClasses" />
                {compartmentInventoryClassId &&
                  !initialInventoryClasses.includes(
                    compartmentInventoryClassId
                  ) &&
                  (
                    value as Required<PromotionalDiscountValues>['inventoryClasses']
                  ).includes(compartmentInventoryClassId) && (
                    <Tooltip
                      content={
                        <TransHint i18nKey="inventoryClassesIncludesCompartment" />
                      }
                    >
                      <Icon name="question" size={16} margin />
                    </Tooltip>
                  )}
              </Stack>
            )}
          />
        }
        multiple
      />
    );
  };
