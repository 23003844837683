import { Stack } from '@mui/material';
import { Button, Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { zoneSelector } from 'features/zoneMap/zoneMapSelectors';
import { FC, useCallback, useMemo } from 'react';
import { TableColumns } from '@fleet/shared/index';
import { Stop } from 'dto/stop';
import { TransTableHead } from 'i18n/trans/table';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router';
import { Table, useIndeterminateRowSelectCheckbox } from '@fleet/shared';
import { useRowSelect, useTable } from 'react-table';
import { deleteStopsFromZone, getZone } from 'features/zoneMap/zoneMapActions';
import _isEmpty from 'lodash/isEmpty';

interface ZoneStopsProps {
  onStopAdd: () => void;
}

const useStyles = makeStyles(
  () => ({
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
  }),
  {
    name: 'StopTable',
  }
);

export const ZoneStops: FC<ZoneStopsProps> = ({ onStopAdd }) => {
  const currentZone = useSelector(zoneSelector);
  const stops = useMemo(() => currentZone!.stops, [currentZone]);
  const { id, zoneId } = useParams<{ id: string; zoneId: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const columns = useMemo<TableColumns<Pick<Stop, 'id' | 'name'>>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="stopName" />,
        width: '90%',
      },
    ],
    []
  );
  const getRowId = useCallback((row) => `${row.id!}`, []);
  const table = useTable(
    {
      data: stops,
      columns,
      getRowId,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedRowIds = useMemo(
    () => Object.keys(table.state.selectedRowIds).map((idx) => +idx),
    [table.state.selectedRowIds]
  );

  const removeSelectedStops = useCallback(async () => {
    const payload = { zoneId, zoneMapId: id };

    await dispatch(deleteStopsFromZone(selectedRowIds));
    dispatch(getZone(payload));
  }, [dispatch, id, selectedRowIds, zoneId]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
        <Button
          variant="text"
          onClick={removeSelectedStops}
          startIcon={<Icon name="trash" />}
          disabled={_isEmpty(selectedRowIds)}
          color="error"
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={onStopAdd}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        classes={{
          cell: classes.tableCell,
        }}
        table={table}
      />
    </>
  );
};
