import type { FC } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { noop } from '@fleet/shared/utils/noop';
import type {
  PriceDetailsTabPricesMap,
  PriceDetailsTabPricesMapValue,
  PriceDetailsTabZonesMap,
  PriceListsPrice,
  PriceListsZone,
} from 'dto/zonePriceList';
import sortBy from 'lodash/sortBy';
import _range from 'lodash/range';
import { copy as copyMap } from 'helpers/map';
import { toNumber } from 'helpers/toNumber';

const range = (start: number, end: number) => _range(start, end + 1);

type ViewMode = 'matrix' | 'list';

interface Selection {
  rows: Map<number, Set<number>>;
  cols: Map<number, Set<number>>;
}

export interface Filter {
  field?: 'all' | 'filled' | 'empty';
  x: { from: number; to: number };
  y: { from: number; to: number };
  price?: Partial<{ from: number; to: number }>;
}

interface PriceDetailsTabPricesContextValue {
  view: ViewMode;
  zones: PriceDetailsTabZonesMap;
  zoneIds: Array<number>;
  prices: PriceDetailsTabPricesMap;
  getPrice: (x: number, y: number) => PriceListsPrice;
  selection: Selection;
  reset: () => void;
  select: (filter: Filter, select: boolean) => PriceDetailsTabPricesMap;
  selectCol: (colIndex: number) => void;
  selectRow: (rowIndex: number) => void;
  selectCell: (colIndex: number, rowIndex: number) => void;
  changePrice: (x: number, y: number, value: string) => void;
  calcPrices: (options: CalcPriceOptions) => void;
}

export const PriceDetailsTabPricesContext =
  createContext<PriceDetailsTabPricesContextValue>({
    view: 'matrix',
    zones: new Map(),
    zoneIds: [],
    prices: new Map(),
    getPrice: noop as unknown as PriceDetailsTabPricesContextValue['getPrice'],
    selection: { rows: new Map(), cols: new Map() },
    reset: noop,
    select: () => new Map(),
    selectCol: noop,
    selectRow: noop,
    selectCell: noop,
    changePrice: noop,
    calcPrices: noop,
  });

type Operator = '=' | '+' | '-' | '*';
export const precisions = [
  '0.01',
  '0.05',
  '0.1',
  '0.5',
  '1.0',
  '10',
  '100',
  '1000',
  '5',
  '50',
  '500',
] as const;
export const roundings = ['HIGHER', 'LOWER', 'ROUND'] as const;
export interface CalcPriceOptions {
  field: 'all' | 'selected' | 'unselected' | 'filled' | 'empty';
  operator: Operator;
  value: string;
  precision: typeof precisions[number];
  rounding: typeof roundings[number];
}

const math: Record<Operator, (x: number, y: number) => number> = {
  '=': (x, y) => y,
  '+': (x, y) => x + y,
  '-': (x, y) => x - y,
  '*': (x, y) => x * y,
};

export function calcPrice(
  price: string,
  options: Omit<CalcPriceOptions, 'field'>
) {
  const { length, [length - 1]: precisionVal } = `${Number(
    options.precision
  )}`.split('.');
  const { length: precisionValLength } = precisionVal;
  const hasDecimalPlaces = length > 1;
  const decimalPlaces = hasDecimalPlaces ? precisionValLength : 0;
  const precision = Number(precisionVal) ?? 1;

  let value = Number(
    (
      math[options.operator](Number(price), toNumber(options.value)) *
      Math.pow(10, decimalPlaces)
    ).toFixed(4 - decimalPlaces)
  );
  const round = () => {
    switch (options.rounding) {
      case 'HIGHER':
        return Math.ceil(Math.ceil(value) / precision) * precision;
      case 'LOWER':
        return Math.floor(Math.floor(value) / precision) * precision;
      default:
        value = Number(parseFloat(`${value}`).toFixed(0));
        return Number((value / precision).toFixed(0)) * precision;
    }
  };

  return (round() / Math.pow(10, decimalPlaces)).toFixed(2);
}

interface PriceDetailsTabPricesContextProviderProps {
  view: ViewMode;
  zones: Array<PriceListsZone>;
  prices: Array<PriceListsPrice>;
}

/**
 * note, converting all zone ids to string, Object.keys returns Array<string>
 * @constructor PriceDetailsTabPricesContextProviderProps
 */
export const PriceDetailsTabPricesContextProvider: FC<PriceDetailsTabPricesContextProviderProps> =
  ({ view, zones = [], prices = [], children }) => {
    const zonesMap = useMemo(
      () =>
        new Map(
          sortBy(zones, 'orderNumber').map(
            ({ id, name, orderNumber, zoneMapZoneId }) => [
              id,
              { id, name, orderNumber, zoneMapZoneId },
            ]
          )
        ),
      [zones]
    );
    const zoneIds = useMemo(() => Array.from(zonesMap.keys()), [zonesMap]);

    const [pricesMap, setPricesMap] = useState<PriceDetailsTabPricesMap>(
      new Map()
    );
    const [selection, setSelection] = useState<Selection>({
      rows: new Map(),
      cols: new Map(),
    });

    const pricesMapRevert = useRef<PriceDetailsTabPricesMap>(new Map());
    useEffect(() => {
      const pricesMap = new Map(
        prices
          .map((price) => {
            const rowIndex = zoneIds.indexOf(price.secondZoneId);
            const colIndex = zoneIds.indexOf(price.firstZoneId);
            if ([rowIndex, colIndex].includes(-1)) return null;
            const key = `${rowIndex}-${colIndex}`;
            return [
              key,
              { key, rowIndex, colIndex, ...price, selected: false },
            ];
          })
          .filter(Boolean) as Array<[string, PriceDetailsTabPricesMapValue]>
      );
      setSelection({
        rows: new Map(),
        cols: new Map(),
      });
      setPricesMap(pricesMap);
      pricesMapRevert.current = copyMap(pricesMap);
    }, [prices, zoneIds]);

    const getPrice = useCallback(
      (x: number, y: number) => {
        const key = `${y}-${x}`;
        if (!pricesMap.has(key)) {
          const firstZoneId = zoneIds[x];
          const secondZoneId = zoneIds[y];
          pricesMap.set(key, {
            key,
            firstZoneId,
            secondZoneId,
            price: '',
            rowIndex: y,
            colIndex: x,
            selected: false,
          });
        }
        return { ...pricesMap.get(key)! };
      },
      [pricesMap, zoneIds]
    );

    const reset = useCallback(() => {
      setPricesMap(copyMap(pricesMapRevert.current));
    }, []);

    const select = useCallback<PriceDetailsTabPricesContextValue['select']>(
      ({ price, field, ...filter }, select) => {
        const { length } = zoneIds;
        const maxPoint = length - 1;
        const yRange = range(
          Math.max(0, filter.y.from),
          Math.min(filter.y.to, maxPoint)
        );
        const xRange = range(
          Math.max(0, filter.x.from),
          Math.min(filter.x.to, maxPoint)
        );

        for (const y of yRange) {
          if (!selection.rows.has(y)) selection.rows.set(y, new Set());
          for (const x of xRange) {
            if (x > y) continue;
            if (!selection.cols.has(x)) selection.cols.set(x, new Set());
            const cell = getPrice(x, y);
            const cellPrice = Number(cell.price);
            if (field && field === 'empty' && cell.price) continue;
            if (price?.from && cellPrice < Number(price.from)) continue;
            if (price?.to && cellPrice > Number(price.to)) continue;
            cell.selected = select;
            pricesMap.set(cell.key, cell);
            selection.rows.get(y)![select ? 'add' : 'delete'](x);
            selection.cols.get(x)![select ? 'add' : 'delete'](y);
          }
        }

        setSelection(() => {
          const toMap = (map: Map<number, Set<number>>) =>
            new Map(
              Array.from(map.entries()).map(([i, sets]) => [
                i,
                new Set(Array.from(sets)),
              ])
            );
          return {
            rows: toMap(selection.rows),
            cols: toMap(selection.cols),
          };
        });

        setPricesMap(new Map(pricesMap));
        return pricesMap;
      },
      [getPrice, pricesMap, selection, zoneIds]
    );

    const selectCol = useCallback<
      PriceDetailsTabPricesContextValue['selectCol']
    >(
      (x) => {
        const length = zoneIds.length;
        select(
          {
            x: { from: x, to: x },
            y: { from: x, to: length },
          },
          selection.cols.get(x)?.size !== length - x
        );
      },
      [select, zoneIds.length, selection.cols]
    );
    const selectRow = useCallback<
      PriceDetailsTabPricesContextValue['selectRow']
    >(
      (y) => {
        select(
          {
            x: { from: 0, to: y },
            y: { from: y, to: y },
          },
          selection.rows.get(y)?.size !== y + 1
        );
      },
      [select, selection.rows]
    );
    const selectCell = useCallback<
      PriceDetailsTabPricesContextValue['selectCell']
    >(
      (x, y) => {
        const cell = getPrice(x, y);
        select(
          {
            x: { from: x, to: x },
            y: { from: y, to: y },
          },
          !cell.selected
        );
      },
      [getPrice, select]
    );

    const changePrice = useCallback<
      PriceDetailsTabPricesContextValue['changePrice']
    >(
      (x, y, value) => {
        const cell = getPrice(x, y);
        cell.price = value;
        pricesMap.set(cell.key, cell);
        setPricesMap(new Map(pricesMap));
      },
      [getPrice, pricesMap]
    );

    const calcPrices = useCallback<
      PriceDetailsTabPricesContextValue['calcPrices']
    >(
      (options) => {
        const { length } = zoneIds;
        for (let y = 0; y < length; y++) {
          for (let x = 0; x < length; x++) {
            if (x > y) continue;
            const cell = getPrice(x, y);
            const price = calcPrice(cell.price || '0', options);
            switch (options.field) {
              case 'all':
                cell.price = price;
                break;
              case 'selected':
                cell && cell.selected && (cell.price = price);
                break;
              case 'unselected':
                !cell.selected && (cell.price = price);
                break;
              case 'filled':
                cell.price && (cell.price = price);
                break;
              case 'empty':
                !cell.price && (cell.price = price);
                break;
            }
            cell && pricesMap.set(cell.key, cell);
          }
        }
        setPricesMap(new Map(pricesMap));
      },
      [getPrice, pricesMap, zoneIds]
    );

    return (
      <PriceDetailsTabPricesContext.Provider
        value={{
          view,
          zones: zonesMap,
          zoneIds,
          prices: pricesMap,
          getPrice,
          selection,
          reset,
          select,
          selectCol,
          selectRow,
          selectCell,
          changePrice,
          calcPrices,
        }}
      >
        {children}
      </PriceDetailsTabPricesContext.Provider>
    );
  };
