import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Button, CardContent, Divider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import {
  api,
  Icon,
  Input,
  SearchResult,
  Table,
  TableColumns,
  useRowActive,
} from '@fleet/shared';
import { Row, useRowSelect, useTable } from 'react-table';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { makeStyles } from '@mui/styles';
import {
  VouchersTemplateBase,
  VoucherTemplatePayload,
} from 'dto/vouchersTemplate';
import { vouchersTemplateListSelector } from 'features/vouchersTemplate/vouchersTemplateSelector';
import { VouchersTemplateSearchForm } from 'routes/VouchersTemplate/VouchersTemplateSearchForm';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { updateVoucherTemplate } from 'features/vouchersTemplate/vouchersTemplateActions';
import { vouchersTemplatesLoadingSelector } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(
  () => ({
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
    thead: {
      zIndex: 1,
    },
  }),
  {
    name: 'UsersTable',
  }
);

interface VouchersTemplateTableProps {}

export const VouchersTemplateTable: FC<VouchersTemplateTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const classes = useStyles();
  const vouchersTemplateList = useSelector(vouchersTemplateListSelector);
  const data = useMemo(
    () => vouchersTemplateList ?? [],
    [vouchersTemplateList]
  );
  const dispatch = useDispatch();
  const loading = useSelector(vouchersTemplatesLoadingSelector);

  const updatePriorityOnBlur = useCallback(
    async (
      event: { target: { value: string } },
      row: Row<VouchersTemplateBase>
    ) => {
      const { data: currentTemplate } = await api.get(
        `/voucher-templates/${row.original.id.toString()}`
      );

      if (currentTemplate) {
        const payload: VoucherTemplatePayload = {
          ...currentTemplate,
          ownerId: +row.original.id,
          voucherTypeId: currentTemplate.voucherType.id,
          voucherUsageTypeId: currentTemplate.voucherUsageType.id,
          printoutThemeId: currentTemplate.printoutTheme.id,
          periodTypeId: currentTemplate.periodType.id,
          priority: +event.target.value,
        };

        dispatch(updateVoucherTemplate(payload));
      }
    },
    [dispatch]
  );

  const link = useCallback(
    (row: Row<VouchersTemplateBase>) =>
      `/vouchers-template/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<VouchersTemplateBase>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="templateName" />,
        Cell: ({ row, value }) => <Link to={link(row)}>{value}</Link>,
      },
      {
        accessor: 'owner',
        Header: <TransTableHead i18nKey="owner" />,
        Cell: ({ value }) => value.name,
      },
      {
        accessor: 'priority',
        Header: <TransTableHead i18nKey="priority" />,
        Cell: ({ value, row }) => {
          return (
            <Input
              value={value}
              fullWidth
              size="small"
              sx={{ width: 104 }}
              required
              onBlur={(event) => updatePriorityOnBlur(event, row)}
              onClick={(event) => event.stopPropagation()}
            />
          );
        },
      },
      {
        accessor: 'voucherType',
        Header: <TransTableHead i18nKey="voucherType" />,
        Cell: ({ value }) => value.name,
      },
      {
        accessor: 'createdVoucherCount',
        Header: <TransTableHead i18nKey="vouchersCount" />,
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="active" />,
        Cell: ({ value }) => (value ? 'Active' : 'Inactive'),
      },
    ],
    [link, updatePriorityOnBlur]
  );

  const table = useTable(
    {
      data,
      columns,
    },
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );

  const {
    state: { selectedRowIds },
    activeFlatRow,
    resetRowActive,
  } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  const hasSelectedRows = useMemo(
    () => Boolean(Object.keys(selectedRowIds).length),
    [selectedRowIds]
  );

  return (
    <>
      <VouchersTemplateSearchForm />
      <Divider />
      <SearchResult results={vouchersTemplateList.length} loading={loading}>
        <Table
          classes={{
            cell: classes.tableCell,
            thead: classes.thead,
          }}
          caption={
            <CardContent>
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle" fontWeight="700">
                  <TransSubtitle i18nKey="searchResults" />
                </Typography>

                {Boolean(vouchersTemplateList.length) && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 2 }}
                  >
                    <TransSubtitle
                      i18nKey="vouchersTemplateQty"
                      values={{ num: vouchersTemplateList.length }}
                    />
                  </Typography>
                )}
                <>
                  <Button
                    startIcon={<Icon name="file" />}
                    sx={{ ml: 'auto', px: 1, py: 0 }}
                    disabled={!hasSelectedRows}
                  >
                    <TransButton i18nKey="export" />
                  </Button>
                  <Button
                    startIcon={<Icon name="trash" />}
                    sx={{ px: 1, py: 0 }}
                    disabled={!hasSelectedRows}
                    color="error"
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                </>
              </Stack>
            </CardContent>
          }
          table={table}
        />
      </SearchResult>
    </>
  );
};
