import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  addStops: <Trans i18nKey="subtitle.addStops" defaults="Add stops" />,
  appliesToTrips: (
    <Trans i18nKey="subtitle.appliesToTrips" defaults="Applies to trips" />
  ),
  carriers: <Trans i18nKey="subtitle.carriers" defaults="Carriers" />,
  changeValues: (
    <Trans i18nKey="subtitle.changeValues" defaults="Change values" />
  ),
  coTaxationGroupsQty: (
    <Trans
      i18nKey="subtitle.coTaxationGroupsQty"
      defaults="(0)[no co-taxation groups];(1)[{{count}} co-taxation group];(2-inf)[{{count}} co-taxation groups];"
    />
  ),
  connectedFareTypes: (
    <Trans
      i18nKey="subtitle.connectedFareTypes"
      defaults="Connected fare types"
    />
  ),
  details: <Trans i18nKey="subtitle.details" defaults="Details" />,
  distanceFares: (
    <Trans
      i18nKey="subtitle.distanceFares"
      defaults="{{name}}: distance fares"
    />
  ),
  editSelection: (
    <Trans i18nKey="subtitle.editSelection" defaults="Edit selection" />
  ),
  fareModelFixedFaresQty: (
    <Trans
      i18nKey="subtitle.fareModelFixedFaresQty"
      defaults="(0)[一];(1)[{{count}} fixed fare];(2-inf)[{{count}} fixed fares];"
    />
  ),
  filterFares: <Trans i18nKey="subtitle.filterFares" defaults="Filter fares" />,
  filterStops: <Trans i18nKey="subtitle.filterStops" defaults="Filter stops" />,
  fixedFares: (
    <Trans i18nKey="subtitle.fixedFares" defaults="{{name}}: fixed fares" />
  ),
  info: <Trans i18nKey="subtitle.info" defaults="Info" />,
  langDependantParamsQty: (
    <Trans
      i18nKey="subtitle.langDependantParamsQty"
      defaults="Language dependent parameters {{num}}"
    />
  ),
  mandatory: <Trans i18nKey="subtitle.mandatory" defaults="(Mandatory)" />,
  newCoTaxationGroup: (
    <Trans
      i18nKey="subtitle.newCoTaxationGroup"
      defaults="New co-taxation group"
    />
  ),
  newPassengerTypeDiscount: (
    <Trans
      i18nKey="subtitle.newPassengerTypeDiscount"
      defaults="New passenger type discount"
    />
  ),
  newVoucherTemplate: (
    <Trans
      i18nKey="subtitle.newVoucherTemplate"
      defaults="New voucher template"
    />
  ),
  newZone: <Trans i18nKey="subtitle.newZone" defaults="New zone" />,
  newZoneMap: <Trans i18nKey="subtitle.newZoneMap" defaults="New zone map" />,
  passengerTypeDiscountsQty: (
    <Trans
      i18nKey="subtitle.passengerTypeDiscountsQty"
      defaults="{{num}} passenger type discounts"
    />
  ),
  passengerTypesQty: (
    <Trans
      i18nKey="subtitle.passengerTypesQty"
      defaults="(0)[no passenger types];(1)[{{count}} passenger type];(2-inf)[{{count}} passenger types];"
    />
  ),
  prices: <Trans i18nKey="subtitle.prices" defaults="Prices" />,
  pricesQty: <Trans i18nKey="subtitle.pricesQty" defaults="{{num}} prices" />,
  promotionalDiscountsQty: (
    <Trans
      i18nKey="subtitle.promotionalDiscountsQty"
      defaults="{{num}} promotional discounts"
    />
  ),
  qtyStops: <Trans i18nKey="subtitle.qtyStops" defaults="{{num}} stops" />,
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
  stops: <Trans i18nKey="subtitle.stops" defaults="Stops" />,
  stopsQty: <Trans i18nKey="subtitle.stopsQty" defaults="Stops {{num}}" />,
  stopsWithoutValue: (
    <Trans i18nKey="subtitle.stopsWithoutValue" defaults="Stops" />
  ),
  termsAndConditions: (
    <Trans
      i18nKey="subtitle.termsAndConditions"
      defaults="Terms and conditions"
    />
  ),
  unitPrices: <Trans i18nKey="subtitle.unitPrices" defaults="Unit price" />,
  voucherInfo: <Trans i18nKey="subtitle.voucherInfo" defaults="Voucher info" />,
  vouchersQty: (
    <Trans i18nKey="subtitle.vouchersQty" defaults="{{num}} vouchers" />
  ),
  vouchersTemplateQty: (
    <Trans
      i18nKey="subtitle.vouchersTemplateQty"
      defaults="{{num}} vouchers templates"
    />
  ),
  zoneMaps: <Trans i18nKey="subtitle.zoneMaps" defaults="Zone maps" />,
  zoneMapsQty: (
    <Trans i18nKey="subtitle.zoneMapsQty" defaults="{{num}} zone maps" />
  ),
  zoneTitle: <Trans i18nKey="subtitle.zoneTitle" defaults="{{name}}" />,
  zones: <Trans i18nKey="subtitle.zones" defaults="Zones" />,
  zonesQty: <Trans i18nKey="subtitle.zoneQty" defaults="{{num}} zones" />,
});
