import { type FC } from 'react';
import { CardHeader, Layout } from '@fleet/shared';
import { VouchersTable } from 'routes/Vouchers/VouchersTable';
import { TransNav } from 'i18n/trans/nav';

interface VouchersProps {}

export const Vouchers: FC<VouchersProps> = () => {
  return (
    <Layout header={<CardHeader title={<TransNav i18nKey="vouchers" />} />}>
      <VouchersTable />
    </Layout>
  );
};
