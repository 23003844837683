import { getPriceLists } from 'features/zonePriceList/zonePriceListActions';
import type { FC } from 'react';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { formatDate } from '@fleet/shared/utils/date';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import type { PriceListsItem } from 'dto/zonePriceList';
import {
  pricesListFilterSelector,
  pricesListListSelector,
} from 'features/zonePriceList/zonePriceListSelectors';
import {
  AddButton,
  CardHeader,
  Checkbox,
  Layout,
  Loadable,
  SearchResult,
  Table,
  TableColumns,
} from '@fleet/shared';
import { useFilters, usePagination, useTable } from 'react-table';
import { PriceDeleteModal } from 'routes/ZonePriceList/modal/PriceDeleteModal';
import { PricesSearchForm } from 'routes/ZonePriceList/PricesSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import { Link, useHistory } from 'react-router-dom';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import { useValidAccessor } from 'hooks/useValidAccessor';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { priceListsLoadingSelector } from 'features/loading/loadingSelectors';

interface PricesSearchTableProps {}

export const PricesSearchTable: FC<PricesSearchTableProps> = () => {
  const history = useHistory();
  const loading = useSelector(priceListsLoadingSelector);
  const prices = useSelector(pricesListListSelector);
  const data = useMemo(() => prices?.items ?? [], [prices]);
  const filter = useSelector(pricesListFilterSelector);
  const initialState = useMemo(
    () => ({ pageSize: 10, hiddenColumns: ['isValid'] }),
    []
  );
  const dispatch = useDispatch();

  const isValidAccessor = useValidAccessor();
  const columns = useMemo<TableColumns<PriceListsItem>>(
    () => [
      {
        accessor: 'name',
        Header: () => <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <Link to={`/zone-fares/pricelists/edit/${row.original.id}`}>
            {value}
          </Link>
        ),
        width: 'auto',
      },
      {
        accessor: 'zoneMap',
        Header: () => <TransTableHead i18nKey="zoneMap" />,
        Cell: ({ value }) => (
          <Link to={`/zone-fares/maps/edit/${value?.id}`}>{value?.name}</Link>
        ),
        width: 'auto',
      },
      {
        accessor: 'currency',
        Header: () => <TransTableHead i18nKey="currency" />,
        Cell: ({ value }) => value?.name,
        width: 'auto',
      },
      {
        accessor: 'fareCategory',
        Header: () => <TransTableHead i18nKey="fareCategory" />,
        Cell: ({ value }) => value.name,
        width: 'auto',
      },
      {
        accessor: 'validity',
        Header: () => <TransTableHead i18nKey="validFromTo" />,
        Cell: ({ value: { from, to } }) =>
          [from, to].map((date) => formatDate(date)).join(' - '),
        width: 'auto',
      },
      {
        accessor: 'id',
        Cell: ({ value }) => <PriceDeleteModal key={value} id={value} />,
        width: 120,
      },
      {
        id: 'isValid',
        accessor: isValidAccessor,
      },
    ],
    [isValidAccessor]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (prices) {
        const { limit = pageSize, offset } = prices;
        return offset / limit;
      }
      return 0;
    },
    [prices]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getPriceLists({ ...filter, ...paginationParams })
      ).unwrap(),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      total: prices?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    useFilters,
    usePagination
  );

  const handleValidFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isValid', e.target.checked || undefined),
    [table]
  );

  return (
    <Layout
      header={
        <CardHeader title={<TransTitle i18nKey="priceList" />}>
          <AddButton
            onClick={() => history.push('/zone-fares/pricelists/create')}
          >
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <Loadable loading={loading}>
        <PricesSearchForm />
        <Divider />
        <SearchResult results={data.length} loading={loading}>
          <Table
            table={table}
            caption={
              <CardContent sx={{ padding: '16px 24px' }}>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 2 }}
                  >
                    <TransSubtitle
                      i18nKey="pricesQty"
                      values={{ num: data?.length }}
                    />
                  </Typography>
                  <Checkbox
                    sx={{ ml: 2 }}
                    onChange={handleValidFilterToggle}
                    label={
                      <Typography
                        variant="body2"
                        color="text.primary"
                        component="span"
                      >
                        <TransField i18nKey="showOnlyValidPricelists" />
                      </Typography>
                    }
                    size="small"
                    inline
                  />
                </Stack>
              </CardContent>
            }
          />
        </SearchResult>
      </Loadable>
    </Layout>
  );
};
