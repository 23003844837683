import {
  FormProvider,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useRowEditActions,
  useRowSelectCheckbox,
} from '@fleet/shared';
import { useRowSelect } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { noop } from '@fleet/shared/utils/noop';

interface PromotionalDiscountTabProps<D extends object> {
  data: Array<D>;
  columns: TableColumns<D>;
  onRowUpdate: (payload: D) => void;
  onRowsRemove: (rows: Array<D>) => Promise<void>;
}

export function PromotionalDiscountTab<D extends object>(
  props: PromotionalDiscountTabProps<D>
) {
  const { data, columns, onRowUpdate, onRowsRemove } = props;
  const { form } = useForm<{
    rows: Array<D>;
  }>({ initialValues: { rows: data } });

  const table = useFormTable(
    {
      data,
      columns,
      form,
      onRowUpdate,
    },
    useRowSelect,
    useRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls<D>({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.state.selectedRowIds).length}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          getTableProps={{ sx: { tableLayout: 'fixed' } }}
          getRowProps={() => ({
            sx: { backgroundColor: 'common.white' },
            onClick: noop,
          })}
          table={table}
        />
      </Box>
    </FormProvider>
  );
}
