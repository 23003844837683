import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  SearchForm,
  useForm,
} from '@fleet/shared';
import { PriceForm } from 'routes/ZonePriceList/PriceForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  getPriceLists,
  setPriceListsFilter,
} from 'features/zonePriceList/zonePriceListActions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { PriceListFormValues } from 'dto/zonePriceList';
import { pricesListFilterSelector } from 'features/zonePriceList/zonePriceListSelectors';

interface PricesSearchFormProps {}

export const PricesSearchForm: FC<PricesSearchFormProps> = () => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(pricesListFilterSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      const payload = {
        ...values,
        validity: values.validity && {
          ...values.validity,
          from: values.validity.from,
          to: values.validity.to,
        },
      };

      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getPriceLists({ ...payload, offset: 0 }));
      });
    },
    [dispatch]
  );

  const initialValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId, ...filter }),
    [currentBusinessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setPriceListsFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm<Partial<PriceListFormValues>>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setPriceListsFilter({}));
  }, [dispatch, form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <PriceForm>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </PriceForm>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
